import React from 'react';
import {wildCardMaper} from 'shared/src/utils';
import { EditorContainer } from './styles';
import 'react-quill/dist/quill.snow.css';

function WildCardsList (wildCard) {
  
    if(wildCard === false) return;
    const cursorPosition = this.quill.getSelection().index
    this.quill.insertText(cursorPosition, ` ${wildCard} `)
    this.quill.setSelection(cursorPosition + (wildCard.length+2))
}
function getContainerOptions(showWildCard){

    const options = [
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'align': [] },{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline'],
    ]

    if( showWildCard === true ) {
        options.push([{ 'placeholder': ['', ...Object.keys(wildCardMaper())]}]);
    }

    return options;
}


export default function Editor({editorValue, handleChangeEditor, showWildCard}) {

    const modules = {
        toolbar: {
            container: getContainerOptions(showWildCard),
            handlers: {
                "placeholder": WildCardsList,
            }
        },
    };

    React.useEffect(()=>{
        if(showWildCard=== true) {
            const placeholderPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-placeholder .ql-picker-item'));
            placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);
            const selector = document.querySelector('.ql-placeholder .ql-picker-label');
            if(selector !== null){
                selector.innerHTML= `
                    <span>Variables</span>
                    <svg style="right: -17px;" viewBox="0 0 18 18">
                        <polygon class="ql-stroke" points="7 11 9 13 11 11 7 11"></polygon>
                        <polygon class="ql-stroke" points="7 7 9 5 11 7 7 7"></polygon>
                    </svg>
                `;
            }
            
        }
    });

    return(
        <>
            <EditorContainer 
                value={editorValue}
                onChange={handleChangeEditor}
                modules={modules}
                placeholder='Escriba el texto ...'
            />
        </>
    );
}