/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useSelector,useDispatch } from 'react-redux';

import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  TablePagination,
  Stack,
  Button,
} from '@mui/material';

import {Menu as MenuIcon, AddCircleOutline as AddCircleOutlineIcon} from '@mui/icons-material';

import NewTemplate from './NewTemplate';
import Actions from './Actions';


import {getNotificationTemplatesListFromService} from 'shared/src/store/notificationSlice';
import { REASON_MAP, TYPE_NOTIFICATIONS_MAP  } from 'shared/src/constants';

import Styles from './styles';

const columns = [
  { id: 'title', align: 'center', label: 'Nombre', minWidth: 170 },
  { id: 'destiny', align: 'center', label: 'Motivo', minWidth: 170 },
  { id: 'type', align: 'center', label: 'Tipo', minWidth: 170 },
  { id: 'message', align: 'center', label: 'Contenido', minWidth: 170, maxWidth:500 },
  { id: 'options', align: 'center', label: 'Opciones', minWidth: 170 },
];

export default function NotificationTemplates() {

  const dispatch = useDispatch();
  const {notificationTemplateList} =useSelector(state=> state.notificationSlice);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = React.useState(null);
  const [openNewTemplate, setOpenNewTemplate] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loadingMoreResults, setLoadingMoreResults] = React.useState(true);

  const formatRow = (row) => {
    return [
      {label: 'title', value: row.title},
      {label: 'destiny' , value: REASON_MAP[row.destiny] || ''},
      {label: 'type' , value: TYPE_NOTIFICATIONS_MAP[row.type] || ''},
      {label: 'message', value: row.message},
    ]
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleMenuClick = (id, event) => {
    setSelectedTemplateId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNewTemplate = () => setOpenNewTemplate(true);

  const requestTemplateListFromService = async () => {
    handleClose(); // evitar que el menu de acciones se quede abierto 
    setLoadingMoreResults(true);
    dispatch(getNotificationTemplatesListFromService({offset: (page * rowsPerPage), limit: rowsPerPage, filter: ''}));
  }

  React.useEffect(()=>{
    requestTemplateListFromService()
  }, [page, rowsPerPage]);

  React.useEffect(()=> {
    if(notificationTemplateList.results !== null){
      setLoadingMoreResults(false);
    }
  },[notificationTemplateList])

  return (
    <>
    <Stack>
      <Typography variant="h1" sx={{alignSelf: 'center', fontSize: '2rem', mt:2}}>Plantillas de Notificaciones</Typography>
      <Box sx={Styles.BoxIcon}>
        <Button onClick={handleOpenNewTemplate} variant="contained" startIcon={<AddCircleOutlineIcon/>} size="small" >
              Nueva Plantilla
        </Button>
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, maxWidth: column.maxWidth, fontWeight: 600 }}
                        >
                          {column.label}
                        </TableCell>
                    ))}
                  </TableRow>
              </TableHead>
              <TableBody>
                  {!loadingMoreResults  && notificationTemplateList.results
                  .map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.IDTemplate}>
                          {
                            formatRow(row).map((cellValue) => (
                                <TableCell
                                  key={cellValue.label}
                                  align="center"
                                >
                                  {cellValue.label === "message"?
                                  <Typography dangerouslySetInnerHTML={{__html: cellValue.value}}></Typography>:
                                  <Typography>{cellValue.value}</Typography>}
                                </TableCell>
                              )
                            )
                          }
                          <TableCell
                            key="actions"
                            align="center"
                          >
                            <IconButton onClick={(e)=>handleMenuClick(row.IDTemplate,e)}>
                              <MenuIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                  })}
                  {
                  loadingMoreResults
                    && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}><CircularProgress/></Box></TableCell></TableRow>
                  }
                  {
                  notificationTemplateList?.results?.length === 0 && !loadingMoreResults
                    && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}>Sin registros</Box></TableCell></TableRow>
                  }
              </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[2,10, 25, 100]}
          component="div"
          count={notificationTemplateList?.recordsFiltered || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Paper>
    </Stack>
      {anchorEl && 
        <Actions 
          requestTemplateListFromService={requestTemplateListFromService}
          handleClose={handleClose}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          selectedTemplateId={selectedTemplateId}
        />
      } 
      <NewTemplate requestTemplateListFromService={requestTemplateListFromService} open={openNewTemplate} setOpen={setOpenNewTemplate}/>            
    </>
  );
}