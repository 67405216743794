import * as React from "react";
import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import AccountActions from "./AccountActions";
import logo from "shared/src/assets/images/fdic.png";

import styles from "./styles";

export default function TopBar() {
  const { account } = useSelector((state) => state.authSlice);
  return (
    <AppBar sx={styles.appBar} position="static">
      <Toolbar disableGutters sx={styles.toolBar}>
        <Box sx={{ ml: 4 }}>
          <Link href="/dashboard">
            <Box alt="app logo" component="img" sx={styles.logo} src={logo} />
          </Link>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mr: 4 }}>
          <Typography variant="subtitle1" sx={styles.subtitle}>
            Hola ! {account.username} [Administrador]
          </Typography>
          <AccountActions />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
