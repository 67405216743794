import React from "react";
import { useParams } from 'react-router-dom';
import {
    Input,
    MenuItem,
    Menu,
    IconButton,
    Box, 
    Grid, 
    Stack, 
    Paper, 
    Tabs, 
    Tab, 
    Button, 
    Typography, 
    TableContainer, 
    Table, 
    TableHead, 
    TableRow, 
    TableCell, 
    TableBody, 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/MoreVert';

import UserInformation from "./UserInformation";
import Notifications from './Notifications';
import Transactions from './Transactions';
import BankAccounts from './BankAccounts';


function UserDetail(){
    // https://www.bezkoder.com/material-ui-image-upload/
    let { userId } = useParams();
    const [value, setValue] = React.useState(0);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [currentFile, setCurrentFile] = React.useState(null);

    const selectFile = event => setCurrentFile(event.target.files[0]);
    
    return(
        <>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <Paper sx={{pb: 3}}>
                        <UserInformation userId={userId}/>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{mt:3}}>
                <Grid item md={12}>
                    <Paper>
                        <Grid item xs={12}>
                            <Paper>
                                <Tabs value={value} onChange={handleChange}>
                                    <Tab label="Notificaciones" />
                                    <Tab label="Transacciones" />
                                    <Tab label="Cuentas de Banco" />
                                    <Tab label="Archivos" />
                                </Tabs>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            {value === 0 && (
                                <Notifications userId={userId}/>
                            )}
                            {value === 1 && (
                                <Transactions userId={userId}/>
                            )}
                            {value === 2 && (
                                <BankAccounts userId={userId}/>
                            )}
                            {value === 3 && (
                                <Paper sx={{ p: 2 }}>
                                    <Stack>
                                        <Box>
                                            <Grid container sx={{padding: 2}}>
                                                <Grid item md={12}>
                                                    <Typography>No disponible por ahora</Typography>
                                                </Grid>
                                                <Grid item md={12} sx={{margin: 2, display: 'none'}}>
                                                    <form onSubmit={()=>{alert('enviando archivo ...')}}>
                                                        <label htmlFor="btn-upload">
                                                            <Box sx={{ display: "flex", justifyContent: 'start'}}>
                                                                
                                                                    <Input
                                                                        id = "btn-upload"
                                                                        name = "btn-upload"
                                                                        type = "file"
                                                                        sx = {{display:'none'}}
                                                                        onChange={selectFile}
                                                                    />
                                                                    <Button
                                                                        type="submit"
                                                                        className="btn-choose"
                                                                        variant="outlined"
                                                                        component="span" >
                                                                        Elegir Archivo
                                                                    </Button>
                                                                    <Typography sx={{ml:2,mr:2 }}>{currentFile?currentFile.name:null}</Typography>
                                                                    <Button variant="outlined" sx={{marginLeft: 'auto'}}>Subir</Button>
                                                            </Box>
                                                        </label>
                                                    </form>
                                                </Grid>
                                                <Grid item md={12} sx={{display: 'none'}}>
                                                    <TableContainer component={Paper}>
                                                        <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Nombre del archivo</TableCell>
                                                                <TableCell>Fecha</TableCell>
                                                                <TableCell>Opciones</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {[].map((account) => (
                                                                <TableRow key={account.id}>
                                                                    <TableCell>{account.name}</TableCell>
                                                                    <TableCell>{`${new Date().getDate()}/${new Date().getMonth()+1}/${new Date().getFullYear()}`}</TableCell>
                                                                    <TableCell>
                                                                        <IconButton onClick={handleClick}>
                                                                            <MenuIcon />
                                                                        </IconButton>
                                                                        <Menu
                                                                            id="simple-menu"
                                                                            anchorEl={anchorEl}
                                                                            keepMounted
                                                                            open={Boolean(anchorEl)}
                                                                            onClose={handleClose}
                                                                        >
                                                                            <MenuItem onClick={handleClose}>Borrar</MenuItem>
                                                                        </Menu>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Stack>
                                </Paper>
                            )}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default UserDetail;