/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import dayjs from 'dayjs';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  TablePagination,
  Stack,
  TableFooter,
  LinearProgress,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

import Actions from './Actions';


import { listTransfers } from 'shared/src/api/transfers';
import { DATE_FORMAT, TRANSFER_STATUS } from 'shared/src/constants';
import { formatDID } from 'shared/src/utils';

const columns = [
  { id: 'username', align: 'center', label: 'Nombre', minWidth: 200 },
  { id: 'DID', align: 'center', label: 'Identificación', minWidth:200 },
  { id: 'status', align: 'center', label: 'Estado', minWidth: 200, maxWidth:500 },
  { id: 'createat', align: 'center', label: 'Fecha de Solicitud', minWidth: 200, maxWidth:500 },
  { id: 'options', align: 'center', label: 'Opciones', minWidth: 100 },
];

const defaultFormatForTransferResponse = {
  results: null,
  limit:0,
  skip:0,
  recordsTotal:0,
} 

export default function Transfers() {

  const intervalId = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedTransferId, setSelectedTransferId] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loadingMoreResults, setLoadingMoreResults] = React.useState(true);
  const [loadingMoreResultsAuto, setLoadingMoreResultsAuto] = React.useState(false);
  const [transferList, setTransferList] = React.useState(defaultFormatForTransferResponse);
  const [userIDSelected, setUserIDSelected] = React.useState(null);

  const formatRow = (row) => {
    return [
      {label: 'username', value: row?.user?.username},
      {label: 'DID', value: formatDID(row?.user)},
      {label: 'status', value:  TRANSFER_STATUS[row.status] || '--'},
      {label: 'createdat', value: dayjs(row.create_at).format(DATE_FORMAT)},
    ]
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleUserAction = () => {
    // Limpiar el intervalo cuando el usuario interactúa.
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
    // Reiniciar el intervalo después de que el usuario ha terminado de interactuar.
    intervalId.current = setInterval(async () => {
      requestTransferListFromService(false);
    }, 60000);
  }

  const handleMenuClick = (event, idTransferSelected, userIDSelected) => {
    setSelectedTransferId(idTransferSelected);
    setUserIDSelected(userIDSelected);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const requestTransferListFromService = async (isUserAction = true) => {
    if(isUserAction){
        handleClose(); // evitar que el menu de acciones se quede abierto 
        setLoadingMoreResults(true);
    } else {
      setLoadingMoreResultsAuto(true);
    }

    try {
      const { status, data: { data } } = await listTransfers({offset: (page * rowsPerPage), limit: rowsPerPage, filter: ''});

      if(status === 200){
      
        setTransferList(data);
      } else {
        setTransferList({...defaultFormatForTransferResponse, results: []});
      }
    } catch (error) {
      console.log(error);
      setTransferList({...defaultFormatForTransferResponse, results: []});
    }

    setLoadingMoreResultsAuto(false);
  }

  React.useEffect(() => {
    requestTransferListFromService()
  }, [page, rowsPerPage]);

  React.useEffect(()=> {
    if(transferList.results !== null) {
      setLoadingMoreResults(false);
    }
  },[transferList])

  React.useEffect(()=>{
    intervalId.current = setInterval(async () => {
      requestTransferListFromService(false);
    }, 60000);
  
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null
      }
    }
  },[]);

  return (
    <Box onClick={handleUserAction}>
    <Stack>
      <Typography variant="h1" sx={{alignSelf: 'center', fontSize: '2rem', mt:2, mb:4}}>Solicitudes de Transferencia</Typography>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                  {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, maxWidth: column.maxWidth, fontWeight: 600 }}
                      >
                        {column.label}
                      </TableCell>
                  ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {!loadingMoreResults  && transferList.results
                .map((row) => {
                    return (
                      <TableRow hover role="checkbox" key={row.IDST}>
                        {
                          formatRow(row).map((cellValue) => (
                              <TableCell
                                key={cellValue.label}
                                align="center"
                              >
                                {cellValue.label === "message"?
                                <Typography dangerouslySetInnerHTML={{__html: cellValue.value}}></Typography>:
                                <Typography>{cellValue.value}</Typography>}
                              </TableCell>
                            )
                          )
                        }
                        <TableCell
                          key="actions"
                          align="center"
                          
                        >
                          <IconButton onClick={(e)=>handleMenuClick(e, row.IDST, row.IDUser)}>
                            <MenuIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                })}
                {
                loadingMoreResults
                  && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}><CircularProgress/></Box></TableCell></TableRow>
                }
                {
                transferList?.results?.length === 0 && !loadingMoreResults
                  && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}>Sin registros</Box></TableCell></TableRow>
                }
            </TableBody>
            <TableFooter sx={{width: '100%'}}>
              <TableRow >
                <TableCell sx={{border: 'none'}} colSpan={3}>
                  <Box>
                    <Stack sx={{ width: '100px', color: 'grey.500', display: (loadingMoreResultsAuto? 'flex': 'none') }} spacing={2}>
                      <LinearProgress color="inherit" />
                    </Stack>
                  </Box>
                </TableCell >
                <TableCell colSpan={2}>
                  <TablePagination
                    rowsPerPageOptions={[2,10, 25, 100]}
                    component="div"
                    count={transferList?.recordsFiltered || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton
                    showLastButton
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Stack>
      {anchorEl && 
        <Actions 
          requestTransferListFromService={requestTransferListFromService}
          handleClose={handleClose}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          selectedTransferId={selectedTransferId}
          userIDSelected={userIDSelected}
        />
      }
    </Box>
  );
}