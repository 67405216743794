/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Stack,
  Typography,
  Button,
} from '@mui/material';

import {Menu as MenuIcon, AddCircleOutline as AddCircleOutlineIcon} from '@mui/icons-material';

import Actions from './Actions';
import SearchBar from './SearchBar';
import NewUserModal from './NewUserModal';


import { getUserListFromService } from 'shared/src/store/userSlice';
import { debounce, formatDID } from 'shared/src/utils';
import { USER_STATUS, DATE_FORMAT } from 'shared/src/constants';

import styles from './styles';

const columns = [
  { id: 'name', align: 'center', label: 'Nombres', minWidth: 170 },
  { id: 'email',align: 'center', label: 'Email', minWidth: 100 },
  {
    id: 'phone',
    label: 'Teléfono',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'id',
    label: 'Identificación',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'state',
    label: 'Estado',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'registerDate',
    label: 'Fecha de Resgistro',
    minWidth: 150,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'options',
    label: 'Opciones',
    minWidth: 120,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
];

export default function Users() {

  const dispatch = useDispatch();
  const {userList} = useSelector(state=> state.userSlice);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [loadingMoreResults, setLoadingMoreResults] = React.useState(true);

  //new user modal function
  const handleOpen = () => {
    setOpen(true)
  };

  const handleCloseCreateUsermodal = () => {
    fetchUser();
    setOpen(false);
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const formatRow = (row) => {
    return [
      {label: 'name', value: row.username},
      {label: 'email' , value:row.email},
      {label: 'phone', value: row.phone ?? "--"},
      {label: 'identification', value: formatDID(row)},
      {label: 'state', value: USER_STATUS[row.status] || '--'},
      {label: 'registerDate', value: dayjs(row.createdate).format(DATE_FORMAT)}
    ]
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleMenuClick = (id, event) => {
    setSelectedUser(id);
    setAnchorEl(event.currentTarget);
  };

  const handleOnChangeSearch = debounce((value)=> {
    setSearchValue(value);
  }, 500);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchUser = () => {
    dispatch(getUserListFromService({offset: (page * rowsPerPage), limit: rowsPerPage, filter: searchValue}));
  }

  React.useEffect(()=>{
    setLoadingMoreResults(true);
    fetchUser();
  }, [page, rowsPerPage, searchValue]);

  React.useEffect(()=> {
    if(userList.results !== null){
      setLoadingMoreResults(false);
    }
  },[userList])

  return (
    <>
      <Stack>
        <Typography variant="h1" sx={{alignSelf: 'center', fontSize: '2rem', mt:2}}>Listado de clientes</Typography>
        <Box sx={{alignSelf: 'end', margin:2}}>
            <Button onClick={handleOpen} variant="contained" startIcon={<AddCircleOutlineIcon/>} size="small" >
              Nuevo Cliente
            </Button>
        </Box>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <SearchBar handleOnChangeSearch={handleOnChangeSearch}/>
          <TableContainer sx={{ maxHeight: 500 }}>
              <Table stickyHeader>
                <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth, fontWeight: 600 }}
                          >
                            {column.label}
                          </TableCell>
                      ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!loadingMoreResults && userList.results
                    .map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.IDUser}>
                            {
                              formatRow(row).map((cellValue) => (
                                  <TableCell
                                    key={cellValue.label}
                                    align="center"
                                  >
                                    {cellValue.value}
                                  </TableCell>
                                )
                              )
                            }
                            <TableCell
                              key="actions"
                              align="center"
                            >
                              <IconButton onClick={(e)=>handleMenuClick(row.IDUser,e)}>
                                <MenuIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                    })}
                    {
                    loadingMoreResults
                      && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}><CircularProgress/></Box></TableCell></TableRow>
                    }
                    {
                    userList?.results?.length === 0 && !loadingMoreResults
                      && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}>Sin registros</Box></TableCell></TableRow>
                    }
                </TableBody>
              </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={userList?.recordsFiltered || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
          />
        </Paper>
      </Stack>
        
      {Boolean(anchorEl) && <Actions handleClose={handleClose} fetchUser={fetchUser} anchorEl={anchorEl} open={Boolean(anchorEl)} selectedUser={selectedUser}/>}
      <NewUserModal open={open} setOpen={handleCloseCreateUsermodal}/>            
    </>
  );
}