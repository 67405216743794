import React from "react";
import Swal from 'sweetalert2/src/sweetalert2.js'
import {
    Box,
    MenuItem,
    Menu,
    Typography,
    Stack,
    Button,
} from '@mui/material'
import {
    Close as CloseIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { CustomModal, CustomSnackbar } from '../../../../SharedComponents'

import { getNotificationFromService, deleteNotificationFromService } from 'shared/src/store/notificationSlice';
import '@sweetalert2/theme-minimal/minimal.scss';

import Styles from '../styles';

const successMessage = "La notificación se ha eliminado correctamente."
const errorMessage = "Ha ocurrido un error, intente más tarde.";

export default function NotificationsMenuOptions(props){
    const {
        userId,
        notyId,
        requestNotificationList,
        anchorElForMenuOption,
        handleCloseForMenuOption
    } = props;

    const [actionButtonsDisabled, setActionButtonsDisabled] = React.useState(false);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false);
    const [opeAlert, setOpenAlert] = React.useState(false);
    const [messageForAlert, setMessageForAlert] = React.useState({});
    const [disableSeeNoty, setDisableSeeNoty] = React.useState(false);

        // funtions to control notification detail
    const handleSeeDetail = async (_,notiId) => {
        setDisableSeeNoty(true);
        const data = await getNotificationFromService(userId, notiId);
        fireBasicSweetAlert(data);
        setDisableSeeNoty(false);
        handleCloseForMenuOption(false);
    }

    // funtions to control delete confirmation
    const handleDeleteNotification = async (e,notiId) => {
        setActionButtonsDisabled(true);
        const data = await deleteNotificationFromService(userId, notiId);
        setActionButtonsDisabled(false);
        if(data){
            requestNotificationList();
            //dispatch(getNotificationListFromService(data.userId));
            setMessageForAlert({message:successMessage,severity:"success" });
        }else{
            setMessageForAlert({message:errorMessage,severity:"error" });
        }
        setOpenAlert(true);
       setOpenDeleteConfirmation(false);
    }
    const handOpenDeleteConfirmation = () => {
        handleCloseForMenuOption(false)
        setOpenDeleteConfirmation(true);
    }
    const handleCloseDeleteConfirmation = (e, reason) => {
        if(reason === "backdropClick") return;
        setOpenDeleteConfirmation(false);
    }

    // for small notification
    const handleCloseAlert = (e, reason)=> {
        if (reason === 'clickaway') return;
        setOpenAlert(false);
    }

    const fireBasicSweetAlert = (infoNoty)=>{
        if (!infoNoty) {
            fireBasicSweetAlert({type:'warning', body: 'No se ha podido Encontrar la notificación, intente nuevamente. '});
        } else {
            Swal.fire({
                icon: infoNoty.type,
                title: infoNoty.title,
                html: infoNoty.body?? infoNoty.message,
                confirmButtonText: 'Cerrar',
                confirmButtonColor: Styles.infoColor,
                showClass: {
                    popup: 'animate__fadeIn'
                },
                hideClass: {
                    popup: 'animate__fadeOut'
                }
            });
        }
    }

    return(
        <>
            <Menu
                anchorEl={anchorElForMenuOption}
                open={Boolean(anchorElForMenuOption)}
                onClose={handleCloseForMenuOption}
            >
                <MenuItem  onClick={(e)=>handleSeeDetail(e, notyId)} disabled={disableSeeNoty}>Ver</MenuItem>
                <MenuItem onClick={handOpenDeleteConfirmation}>Borrar</MenuItem>
            </Menu>
            {/* to confirm delete notifications*/}
            <CustomModal
                handleClose={handleCloseDeleteConfirmation}
                open={openDeleteConfirmation}
                customStyles={{width:'500px'}}
            >
                <Stack>
                    <Typography variant="h7" align="center">Seguro que desea Borrar la notificación?</Typography>
                    <Box sx={{display:'flex', justifyContent: 'center', mt:2}}>
                        <Button
                            onClick={(e)=>handleDeleteNotification(e, notyId)}
                            disabled={actionButtonsDisabled}
                            startIcon={<DeleteIcon />}
                            variant="contained"
                            color="error"
                            sx={{mr:1}}
                            disableElevation
                        >
                            Borrar
                        </Button>
                        <Button
                            onClick={handleCloseDeleteConfirmation}
                            disabled={actionButtonsDisabled}
                            startIcon={<CloseIcon />}
                            variant="contained"
                            color="success"
                            sx={{ml:1}}
                            disableElevation
                        >
                            Cancelar
                        </Button>
                    </Box>
                </Stack>
            </CustomModal>
            {/* small alert at the bottom left to confirm/deny operation*/}
            <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert} />
        </>
    );
}