import * as React from 'react';
import { deleteNotificationTemplateFromService} from 'shared/src/store/notificationSlice'
import {Menu, MenuItem, Stack, Typography, Box, Button} from '@mui/material';
import {Delete as DeleteIcon, Close as CloseIcon} from '@mui/icons-material';
import EditTemplate from './EditTemplate';
import {CustomModal, CustomSnackbar} from '../SharedComponents'

const successMessage = "La Plantilla se ha eliminado correctamente."
const errorMessage = "Ha ocurrido un error, intente más tarde.";

export default function Actions(props) {
  const {
    handleClose,
    anchorEl,
    open,
    selectedTemplateId,
    requestTemplateListFromService,
  } = props;

  const [actionButtonsDisabled, setActionButtonsDisabled] = React.useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false);
  const [opeAlert, setOpenAlert] = React.useState(false);
  const [messageForAlert, setMessageForAlert] = React.useState({});
  const [openNewTemplate, setOpenNewTemplate] = React.useState(false);


  const handleCloseDeleteConfirmation = (e, reason) => {
    if(reason === "backdropClick") return;
    setOpenDeleteConfirmation(false);
  }

  const handOpenDeleteConfirmation = () => {
    setOpenDeleteConfirmation(true);
  }

  const handleOpenNewTemplate = () => {
    setOpenNewTemplate(true);
  };

  const handleDeleteNotification = async (_,selectedTemplateId) => {
    setActionButtonsDisabled(true);
    const data = await deleteNotificationTemplateFromService(selectedTemplateId);
    setActionButtonsDisabled(false);
    if(data){
      setMessageForAlert({message:successMessage,severity:"success" });
    }else{
        setMessageForAlert({message:errorMessage,severity:"error" });
    }
    setOpenAlert(true);
    setOpenDeleteConfirmation(false);
    setTimeout(() => {
      requestTemplateListFromService()
    }, 500); 
  }

  // for small notification
  const handleCloseAlert = (_, reason)=> {
    if (reason === 'clickaway') return;
    setOpenAlert(false);
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenNewTemplate}>Editar</MenuItem>
        <MenuItem onClick={handOpenDeleteConfirmation}>Borrar</MenuItem>
      </Menu>
      {/* to confirm delete notifications*/}
      <CustomModal
        handleClose={handleCloseDeleteConfirmation}
        open={openDeleteConfirmation}
        customStyles={{width:'500px'}}
      >
        <Stack>
            <Typography variant="h7" align="center">Seguro que desea Borrar la Plantilla?</Typography>
            <Box sx={{display:'flex', justifyContent: 'center', mt:2}}>
                <Button
                    onClick={(e)=>handleDeleteNotification(e, selectedTemplateId)}
                    disabled={actionButtonsDisabled}
                    startIcon={<DeleteIcon />}
                    variant="contained"
                    color="error"
                    sx={{mr:1}}
                    disableElevation
                >
                    Borrar
                </Button>
                <Button
                    onClick={handleCloseDeleteConfirmation}
                    disabled={actionButtonsDisabled}
                    startIcon={<CloseIcon />}
                    variant="contained"
                    color="success"
                    sx={{ml:1}}
                    disableElevation
                >
                    Cancelar
                </Button>
            </Box>
        </Stack>
      </CustomModal>
      {/* small alert at the bottom left to confirm/deny operation*/}
      <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert} />
      {/* Para editar las notificaciones*/}
        {openNewTemplate && <EditTemplate
          requestTemplateListFromService = {requestTemplateListFromService}
          open = {openNewTemplate}
          setOpen = {setOpenNewTemplate}
          selectedTemplateId = {selectedTemplateId}
        />}
    </>
  );
}