/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Link,
  Box,
  Typography,
  Container,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  CircularProgress,
  Stack,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import {
  LockOutlined as LockOutlinedIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

import { CustomSnackbar } from "../SharedComponents";
import TopBar from "./TopBar";
import { loginFromService, setAuthError } from "shared/src/store/authSlice";

import Styles from "./styles";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://fxinfinity.net/" target="_blank">
        fxinfinity.net
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { account, authError, logoutStatus } = useSelector(
    (state) => state.authSlice
  );

  const [disableSendButton, setDisableSendButton] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  const [openSnackbarLogout, setOpenSnackbarLogout] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const [messageForAlert, setMessageForAlert] = React.useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    setDisableSendButton(true);
    const data = new FormData(event.currentTarget);
    dispatch(loginFromService(data.get("email"), data.get("password")));
  };

  // for small notification
  const handleCloseAlert = (e, reason) => {
    if (reason === "clickaway") return;
    setOpenSnackbarError(false);
    setOpenSnackbarLogout(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  React.useEffect(() => {
    if (account !== null) {
      navigate("/users");
    }
  }, [account]);

  React.useEffect(() => {
    if (authError !== false) {
      if (authError === 404) {
        setMessageForAlert({
          message: "La combinación de usuario y contraseña no es correcta",
          severity: "error",
        });
      } else if (authError === 0) {
        setMessageForAlert({
          message: "Solo usuarios administradores pueden ingresar",
          severity: "error",
        });
      } else {
        setMessageForAlert({
          message: "Ha ocurrido un error interno",
          severity: "error",
        });
      }
      setOpenSnackbarError(true);
      setDisableSendButton(false);
      dispatch(setAuthError(false));
    }
  }, [authError]);

  React.useEffect(() => {
    if (logoutStatus === true) {
      setMessageForAlert({
        message: "Se ha cerrado la sesión",
        severity: "success",
      });
      setOpenSnackbarLogout(true);
    }
  }, [logoutStatus]);

  return (
    <>
      <Stack>
        <TopBar />
        <Container component="main" maxWidth="xs" sx={Styles.container}>
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={Styles.toformAvatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h2" sx={{ fontSize: "20px" }}>
              Iniciar sesión como Administrador
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1, mr: 2, ml: 2 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <FormControl sx={{ width: "100%", mt: 2 }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Contraseña *
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Contraseña *"
                />
              </FormControl>

              {/* <FormControlLabel
                sx={{display: 'none'}}
                control={<Checkbox value="remember" color="primary" />}
                label="Recordar mi contraseña"
              /> */}
              <Button
                disabled={disableSendButton}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {!disableSendButton && "Iniciar Sesión"}
                {disableSendButton && (
                  <CircularProgress color="inherit" size={25} />
                )}
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Olvidó la Contraseña?
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
          {/* <Copyright sx={{ mt: 8, mb: 4}} /> */}
        </Container>
      </Stack>

      <CustomSnackbar
        opeSnackbar={openSnackbarError}
        messageForAlert={messageForAlert}
        handleCloseAlert={handleCloseAlert}
      />
      <CustomSnackbar
        opeSnackbar={openSnackbarLogout}
        messageForAlert={messageForAlert}
        handleCloseAlert={handleCloseAlert}
      />
    </>
  );
}
