import React from "react";
import { useForm, Controller } from "react-hook-form";
import dayjs from 'dayjs';
import {Grid, Typography, Button, TextField, Box, CircularProgress} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import {CustomModal, CustomSnackbar} from '../../../SharedComponents';

import { createTransactionFromService } from 'shared/src/store/transactionSlice'

import { DATE_TIME_FORMAT } from 'shared/src/constants';

const requireErrorMessage = "Este campo es requerido";
const successMessage = "La transacción se ha creado con éxito"
const errorMessage = "Ha ocurrido un error";

const defaultValuesFordateField = {
    orderNro:   '',
    datetime:   dayjs(),
    description:   '',
    amount:  '',
}


export default function NewTransactionModal({ getTransactionslistFromService, open, setOpen, userId}) {

    const [messageForAlert, setMessageForAlert] = React.useState({});
    const [opeAlert, setOpenAlert] = React.useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

    const handleClose = (_, reason) => {
        if(reason === "backdropClick") return;
        setActionButtonDisabled(false);
        reset(defaultValuesFordateField);
        setOpen(false);
    };

    const { control, handleSubmit, reset } = useForm({
        defaultValues: defaultValuesFordateField
    });

    const handleOnSubmit = async data =>{
        console.log(data);
        setActionButtonDisabled(true);
        const result = await createTransactionFromService(userId, {...data, datetime: data.datetime.format()});

        if(result) {
            setMessageForAlert({message:successMessage,severity:"success" });
            
        }else {
            setMessageForAlert({message:errorMessage,severity:"error" });
        }
        setOpenAlert(true);
        getTransactionslistFromService();
        reset(defaultValuesFordateField);
        handleClose(); // to close modal
    }

    // for small notification
    const handleCloseAlert = (e, reason)=> {
        if (reason === 'clickaway') return;
        setOpenAlert(false);
    }

    return(
        <>
            <CustomModal handleClose={handleClose} open={open} customStyles={{width:'40%'}}>
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container alignItems="center" justify="center" direction="row" spacing={3}>
                        <Grid item md={12}>
                            <Typography variant="h6" sx={{mb:2, textAlign: 'center'}}>
                                Crear una nueva Transacción.
                            </Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                name="orderNro"
                                control={control}
                                rules={{required: requireErrorMessage, maxLength: 100}}
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Número de Orden *"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                name="datetime"
                                control={control}
                                rules={{required: requireErrorMessage}}
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                        <DateTimePicker
                                            sx={{width: '100%'}}
                                            label="Fecha y Hora *"
                                            format={DATE_TIME_FORMAT}
                                            disableFuture
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                name="amount"
                                control={control}
                                rules={
                                    {
                                        required: requireErrorMessage, 
                                        pattern: { 
                                            value: /^[0-9-]+$/,
                                            message: "Este campo solo acepta números y signos menos (-)"
                                        }
                                    }
                                }
                                render={
                                    ({ field, fieldState: { invalid, error }}) =>(
                                        <TextField
                                        fullWidth
                                        label="valor *"
                                        error={invalid}
                                        helperText={error?.message}
                                        {...field}
                                        inputRef={field.ref}
                                    />)
                                }
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                name="description"
                                control={control}
                                rules={{required: false, maxLength: 100}}
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Descripción"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item md={12} sx={{display: 'flex', justifyContent: 'center'}}>
                            <Button onClick={handleClose} variant="contained" color="error" sx={{mr:1}} disableElevation >Cancelar</Button>
                            <Box sx={{position: 'relative' }}>
                                <Button variant="contained" color="primary" type="submit" disabled={actionButtonDisabled}>
                                Crear Transacción
                                </Button>
                                {actionButtonDisabled && (
                                    <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </CustomModal>
            <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert}/>
        </>
    );
}