import React from "react";
import { Outlet } from "react-router-dom";
import { Container, Grid, Stack, Typography, Box } from "@mui/material";

import Styles from './styles';

function MainArea(){
    return (
        <Box sx={Styles.boxContainer}>
            <Outlet />
        </Box>
    );
}

export default MainArea;