import {palette} from 'shared/src/styles/palette'

const Styles = {
    Box: {
        border: `1px solid ${palette.gray[100]}`,
    },
    BoxActionButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    List: {
        width: '100%',
        height: '250px',
        overflow: 'auto',
        overFlowY: 'auto'
    },
    title: {
        textAlign: 'center',
    },
}

export default Styles;