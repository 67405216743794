import React from "react";
import { useForm, Controller } from "react-hook-form";
import {Grid, Typography, Button, TextField, MenuItem, CircularProgress, Box} from '@mui/material';
import {CustomModal, CustomSnackbar} from '../SharedComponents';

import { createUserFromService  } from 'shared/src/store/userSlice';

import { DID_TYPES } from 'shared/src/constants';

const requireErrorMessage = "Este campo es requerido";
const successMessage = "El Administrador se ha creado con éxito"
const errorMessage = "Ha ocurrido un error";

const defaultValuesFordateField = {
    username:   '',
    typeDID:   '',
    DID:   '',
    phone:  '',
    email:  '',
    password:  '',
}

export default function NewAdminModal({open, setOpen}) {
    const [messageForAlert, setMessageForAlert] = React.useState({});
    const [opeAlert, setOpenAlert] = React.useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

    const handleClose = (_, reason) => {
        if(reason === "backdropClick") return;
        reset(defaultValuesFordateField);
        setOpen(false);
    };
    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: defaultValuesFordateField
    });

    const handleOnSubmit = async data =>{
        setActionButtonDisabled(true);
        try {
            await createUserFromService({
                ...data,
                profile: 'ADMIN',
            });
            setMessageForAlert({message:successMessage,severity:"success" });
        } catch (error) {
            console.log(error);
            setMessageForAlert({
                message: error?.response?.data?.msg || errorMessage,
                severity:"error"
            });
        }
        setActionButtonDisabled(false);
        reset(defaultValuesFordateField);
        setOpenAlert(true);
        handleClose(); // to close modal
       
    }

    // for small notification
    const handleCloseAlert = (e, reason)=> {
        if (reason === 'clickaway') return;
        setOpenAlert(false);
    }

    const DID = watch("DID");
    const typeDID = watch("typeDID");

    return(
        <>
            <CustomModal handleClose={handleClose} open={open} customStyles={{width:'40%'}}>
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container alignItems="center" justify="center" direction="row" spacing={3}>
                        <Grid item md={12}>
                            <Typography variant="h6" sx={{mb:2, textAlign: 'center'}}>
                                Crear Nuevo Administrador.
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Controller
                                name="username"
                                control={control}
                                rules={{required: requireErrorMessage, maxLength: 100}}
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Nombre(s) y apellido(s) *"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item md={2}>
                            <Controller
                                name="typeDID"
                                control={control}
                                rules={
                                    {
                                        required: (DID !== '')? requireErrorMessage : false,
                                    }
                                }
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                    <TextField
                                        fullWidth
                                        select
                                        label="Tipo"
                                        error={invalid}
                                        helperText={error?.message}
                                        {...field}
                                        inputRef={field.ref}
                                    >
                                        <MenuItem key="" value="">
                                            --
                                        </MenuItem>
                                        {DID_TYPES.map((didType) => <MenuItem key={didType.type} value={didType.type}>{didType.label}</MenuItem>)}
                                    </TextField>)
                                }
                            />
                        </Grid>
                        <Grid item  md={10}>
                            <Controller
                                name="DID"
                                control={control}
                                rules={
                                    {
                                        required: (typeDID !== '')? requireErrorMessage : false,
                                        pattern: { 
                                            value: /^[0-9-]+$/,
                                            message: "Este campo solo acepta números y guiones (-)"
                                        }
                                    }
                                }
                                render={
                                    ({ field, fieldState: { invalid, error }}) =>(
                                        <TextField
                                        fullWidth
                                        label="Número de Identificación"
                                        error={invalid}
                                        helperText={error?.message}
                                        {...field}
                                        inputRef={field.ref}
                                    />)
                                }
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Controller
                                name="phone"
                                control={control}
                                rules={
                                    {
                                        required: false,
                                        pattern: { 
                                            value: /^[\d+\s]+$/i,
                                            message: "Ingresa solo números y espacios"
                                          }
                                    }
                                }
                                render={
                                    ({ field, fieldState: { invalid, error }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Teléfono"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Controller
                                name="email"
                                control={control}
                                rules={
                                    {
                                        required: requireErrorMessage,
                                        pattern: { 
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Ingresa una dirección de correo electrónico válida"
                                        }
                                    }
                                }
                                render={
                                    ({ field, fieldState: { invalid, error }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Email *"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Controller
                                name="password"
                                control={control}
                                rules={
                                    {
                                        required: requireErrorMessage,
                                        minLength: {
                                            value: 5,
                                            message: 'Al menos 5 caracteres son requeridos'
                                        }
                                    }
                                }
                                render={
                                    ({ field, fieldState: { invalid, error }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Contraseña *"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item md={12} sx={{display: 'flex', justifyContent: 'center'}}>
                            <Button onClick={handleClose} variant="contained" color="error" sx={{mr:1}} disableElevation >Cancelar</Button>
                            <Box sx={{position: 'relative' }}>
                                <Button variant="contained" color="primary" type="submit" disabled={actionButtonDisabled}>
                                    Crear Admin
                                </Button>
                                {actionButtonDisabled && (
                                    <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </CustomModal>
            <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert}/>
        </>
    );
}