import React from "react";

import {Grid, CssBaseline, Container, Box} from '@mui/material';
import TopBar from "./TopBar";
import SideBar from "./SideBar";
import MainArea from "./MainArea";

import Styles from "./styles";

function Layout(){

    return(<Box>
        <CssBaseline />
        <TopBar/>
        <Container  maxWidth="xl">
            <Box sx={{height: '100vh', mt:2 }}>
                <Grid container  spacing={0}>
                    <Grid item md={2} sx={Styles.GridManiArea}>
                        <SideBar/>
                    </Grid>
                    <Grid item md={10}>
                        <MainArea/>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    </Box>);

    // return (
    //     <React.Fragment>
    //         
    //         <Container fixed>
    //             <Box sx={{ bgcolor: '#cfe8fc', height: '100vh' }} />
    //         </Container>
    //     </React.Fragment>
    // )

    // return (
    //     <Grid container  spacing={0}>
    //         <Grid item md={12} sx={{width:'100%'}}>
    //             <TopBar/>
    //         </Grid>
    //         <Grid item md={2} sx={Styles.GridManiArea}>
    //             <SideBar/>
    //         </Grid>
    //         <Grid item md={10}>
    //             <Grid item md={12}>
    //                 <MainArea/>
    //             </Grid>
                
    //         </Grid>
    //     </Grid>
    // )
}

export default Layout;
