import React from "react";
import {
    Box,
    MenuItem,
    Menu,
    Typography,
    Stack,
    Button,
    Divider,
} from '@mui/material'
import {
    Close as CloseIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { CustomModal, CustomSnackbar } from '../../../SharedComponents';
import UpdateBankAccountStatusModal from './UpdateBankAccountStatusModal';

import { deleteBankAccountFromService } from 'shared/src/store/accountSlice';

const successMessage = "La Cuenta Bancaria se ha eliminado correctamente."
const errorMessage = "Ha ocurrido un error, intente más tarde.";

export default function MenuOptions(props){
    const {
        BankAccountId,
        anchorElForMenuOption,
        handleCloseForMenuOption,
        getAccountlistFromService,
        userId,
    } = props

    const [actionButtonsDisabled, setActionButtonsDisabled] = React.useState(false);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false);
    const [openUpdateBankAccountModal, setOpenUpdateBankAccountModal] = React.useState(false);

    const [opeAlert, setOpenAlert] = React.useState(false);
    const [messageForAlert, setMessageForAlert] = React.useState({});

    // funtions to control delete confirmation
    const handleDeleteBankAccount = async _ => {
        setActionButtonsDisabled(true);
        const data = await deleteBankAccountFromService(userId, BankAccountId);
        setActionButtonsDisabled(false);
        if(data){
            getAccountlistFromService();
            setMessageForAlert({message:successMessage,severity:"success" });
        }else{
            setMessageForAlert({message:errorMessage,severity:"error" });
        }
        setOpenAlert(true);
       setOpenDeleteConfirmation(false);
    }
    const handOpenDeleteConfirmation = () => {
        handleCloseForMenuOption(false)
        setOpenDeleteConfirmation(true);
    }

    const handleChangeStatus = (_) => {
        handleCloseForMenuOption(false)
        setOpenUpdateBankAccountModal(true);
    }

    const handleCloseDeleteConfirmation = (_, reason) => {
        if(reason === "backdropClick") return;
        setOpenDeleteConfirmation(false);
    }

    // for small notification
    const handleCloseAlert = (e, reason)=> {
        if (reason === 'clickaway') return;
        setOpenAlert(false);
    }

    return(
        <>
            <Menu
                anchorEl={anchorElForMenuOption}
                open={Boolean(anchorElForMenuOption)}
                onClose={handleCloseForMenuOption}
            >
                <MenuItem onClick={handleChangeStatus}>Cambiar Estado</MenuItem>
                <Divider sx={{display: 'none'}} />
                <MenuItem sx={{display: 'none'}} onClick={handOpenDeleteConfirmation}>Borrar</MenuItem>
            </Menu>
            {/* to confirm delete notifications*/}
            <CustomModal
                handleClose={handleCloseDeleteConfirmation}
                open={openDeleteConfirmation}
                customStyles={{width:'500px'}}
            >
                <Stack>
                    <Typography variant="h7" align="center">Seguro que desea Borrar la Transacción?</Typography>
                    <Box sx={{display:'flex', justifyContent: 'center', mt:2}}>
                        <Button
                            
                            onClick={(e)=>handleDeleteBankAccount(e)}
                            disabled={actionButtonsDisabled}
                            startIcon={<DeleteIcon />}
                            variant="contained"
                            color="error"
                            sx={{mr:1}}
                            disableElevation
                        >
                            Borrar
                        </Button>
                        <Button
                            onClick={handleCloseDeleteConfirmation}
                            disabled={actionButtonsDisabled}
                            startIcon={<CloseIcon />}
                            variant="contained"
                            color="success"
                            sx={{ml:1}}
                            disableElevation
                        >
                            Cancelar
                        </Button>
                    </Box>
                </Stack>
            </CustomModal>
            {/*modal to edit BankAccount status */}
            {openUpdateBankAccountModal && <UpdateBankAccountStatusModal
                getAccountlistFromService = {getAccountlistFromService}
                open={openUpdateBankAccountModal}
                setOpen={setOpenUpdateBankAccountModal}
                userId={userId}
                BankAccountId={BankAccountId}
            />}
            {/* small alert at the bottom left to confirm/deny operation*/}
            <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert} />
        </>
    );
}