import { palette } from "shared/src/styles/palette";
import { theme } from "shared";

const styles = {
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
  },
  appBar: {
    height: "80px",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  logo: {
    mt: "0px",
    ml: "10px",
    width: "150px",
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
  },
  subtitle: {
    color: palette.common.black,
    mr: 4,
  },
};

export default styles;
