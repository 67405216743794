import React from "react";
import { useForm, Controller } from "react-hook-form";
import {Grid, Typography, Button, TextField, Box, CircularProgress, MenuItem} from '@mui/material';
import {CustomModal, CustomSnackbar} from '../SharedComponents';
import { updateTransfer } from 'shared/src/api/transfers';
import { TRANSFER_STATUS } from 'shared/src/constants';

const requireErrorMessage = "Este campo es requerido";
const successMessage = "La solicitud de transferencia se ha actualizado con exito";
const errorMessage = "Ha ocurrido un error";

const defaultValuesFordateField = {
    status: '',
}


export default function EditTransferRequest(props) {

    const {
        requestTransferListFromService,
        open,
        setOpen,
        selectedTransferId,
    } = props;

    const [messageForAlert, setMessageForAlert] = React.useState({});
    const [opeAlert, setOpenAlert] = React.useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

    const handleClose = (_, reason) => {
        if(reason === "backdropClick") return;
        setActionButtonDisabled(false);
        reset(defaultValuesFordateField);
        setOpen(false);
    };

    const { control, handleSubmit, reset } = useForm({
        defaultValues: defaultValuesFordateField,
    });

    const handleOnSubmit = async data =>{

        setActionButtonDisabled(true);
        try {
            await updateTransfer(selectedTransferId, data);
            setMessageForAlert({message:successMessage,severity:"success" });
        } catch (error) {
            console.log(error);
            setMessageForAlert({message:errorMessage,severity:"error" });
        }
        setOpenAlert(true);
        requestTransferListFromService();
        reset(defaultValuesFordateField);
        handleClose(); // to close modal
    }

    // for small notification
    const handleCloseAlert = (e, reason)=> {
        if (reason === 'clickaway') return;
        setOpenAlert(false);
    }

    React.useEffect(() => {
    },[]);

    return(
        <>
            <CustomModal handleClose={handleClose} open={open} customStyles={{width:'400px'}}>
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container alignItems="center" justify="center" direction="row" spacing={3}>
                        <Grid item md={12}>
                            <Typography variant="h6" sx={{mb:2, textAlign: 'center'}}>
                                Cambiar Estado de solicitud de transferencia a:
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Controller
                                name="status"
                                control={control}
                                rules={
                                    {
                                        required: requireErrorMessage,
                                    }
                                }
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                    <TextField
                                        fullWidth
                                        select
                                        label="Estado *"
                                        error={invalid}
                                        helperText={error?.message}
                                        {...field}
                                        inputRef={field.ref}
                                    >
                                        {Object.entries(TRANSFER_STATUS).map(([key,value]) => <MenuItem key={key} value={key}>{value}</MenuItem>)}
                                    </TextField>)
                                }
                            />
                        </Grid>
                        <Grid item md={12} sx={{display: 'flex', justifyContent: 'center'}}>
                            <Button onClick={handleClose} variant="contained" color="error" sx={{mr:1}} disableElevation >Cancelar</Button>
                            <Box sx={{position: 'relative' }}>
                                <Button variant="contained" color="primary" type="submit" disabled={actionButtonDisabled}>
                                    Aceptar
                                </Button>
                                {actionButtonDisabled && (
                                    <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </CustomModal>
            <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert}/>
        </>
    );
}