/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector,useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import {
    Box,
    Grid,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TablePagination,
    CircularProgress,
    Button,
} from '@mui/material'
import {
    AddCircleOutline as AddCircleOutlineIcon,
    Menu as MenuIcon,
} from '@mui/icons-material';

import NewNotificationModal from './NewNotificationModal';
import NotificationsMenuOptions from './NotificationsMenuOptions'

import { getNotificationListFromService } from 'shared/src/store/notificationSlice';
import  {REASON_MAP, NOTY_STATE, DATE_FORMAT, TYPE_NOTIFICATIONS_MAP} from 'shared/src/constants';
import { cutText } from "shared/src/utils";

import Styles from "./styles";



export default function Notifications({userId}) {

    const dispatch = useDispatch();
    const { notificationList } = useSelector(state=> state.notificationSlice);

    const [open, setOpen] = React.useState(false);
    const [notyId, setNotyId] = React.useState(null);
    const [anchorElForMenuOption, setAnchorElForMenuOption] = React.useState(null);
    const [loadingMoreResults, setLoadingMoreResults] = React.useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const columns = [
        { id: 'destiny', label: 'Motivo', sx: Styles.column  },
        { id: 'type', label: 'Tipo', sx: Styles.column  },
        { id: 'body', label: 'Mensaje', sx: Styles.column  },
        { id: 'status', label: 'Estado', sx: Styles.column  }, //to show if user has read the noty,
        { id: 'datecreated', label: 'Fecha de envío', sx: Styles.column  }, //to show if user has read the noty,
        { id: 'options', label: 'Opciones', sx: Styles.column  },
    ];

    const handleClickMenuIcon = (e, notyId) => {
        setNotyId(notyId);
        setAnchorElForMenuOption(e.currentTarget);
    };

    const handleOpen = () => setOpen(true);

    const closeNewNotificationModal = () => {
        setOpen(false);
        requestNotificationList();
    }

    const formatRow = (row) => {
        return [
            {label:'destiny', value: REASON_MAP[row.destiny] || ""},
            {label:'type', value: TYPE_NOTIFICATIONS_MAP[row.type] || ""},
            {label:'body', value: cutText(row.body)},
            {label:'status', value: NOTY_STATE[row.status] || "No definido"},
            {label:'datecreated', value: dayjs(row.createdate).format(DATE_FORMAT)},
        ]
    }

    const handleCloseForMenuOption = () => {
        setAnchorElForMenuOption(null);
    };

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const requestNotificationList = ()=> {
        setLoadingMoreResults(true);
        dispatch(getNotificationListFromService({userId, offset: (page * rowsPerPage), limit: rowsPerPage, filter: ''}));
    }

    React.useEffect(()=>{
        requestNotificationList();
      }, [page, rowsPerPage]);

    React.useEffect(()=> {
        if(notificationList.results !== null){
          setLoadingMoreResults(false);
        }
    },[notificationList])

    return(
        <Box>
            <Grid container >
                <Grid item md={12}>
                    <Box sx={Styles.BoxIcon}>
                        <Button onClick={handleOpen} variant="contained" startIcon={<AddCircleOutlineIcon/>} size="small" >
                            Nueva Notificación
                        </Button>
                    </Box>
                </Grid>
                <Grid item md={12}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            sx={column.sx}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!loadingMoreResults && notificationList.results.map((noti) => (
                                    
                                    <TableRow key={noti.IDNoty} >
                                        {
                                            formatRow(noti).map((cellValue) => (
                                                <TableCell
                                                    key={cellValue.label}
                                                    align="center"
                                                >
                                                    {cellValue.label === "body"?
                                                    <Typography dangerouslySetInnerHTML={{__html: cellValue.value}}></Typography>:
                                                    <Typography>{cellValue.value}</Typography>}
                                                </TableCell>
                                            )
                                            )
                                        }
                                        <TableCell align="center">
                                            <Box>
                                                <IconButton onClick={(e)=>handleClickMenuIcon(e, noti.IDNoty)}>
                                                    <MenuIcon />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {
                                    loadingMoreResults
                                    && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}><CircularProgress/></Box></TableCell></TableRow>
                                }
                                {
                                    notificationList?.results?.length === 0 && !loadingMoreResults
                                    && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}>Sin registros</Box></TableCell></TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[3,10, 25, 100]}
                        component="div"
                        count={notificationList?.recordsFiltered || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton
                        showLastButton
                    />
                </Grid>
            </Grid>
            <NewNotificationModal open={open} closeNewNotificationModal={closeNewNotificationModal} userId={userId}/>
            <NotificationsMenuOptions 
                userId={userId}
                notyId={notyId}
                requestNotificationList={requestNotificationList}
                anchorElForMenuOption = {anchorElForMenuOption}
                handleCloseForMenuOption={handleCloseForMenuOption}
            />
        </Box>
    )
}