import React from 'react';
import {
    Person as PersonIcon,
    CurrencyExchange as CurrencyExchangeIcon,
    CreditCard as CreditCardIcon,
    NotificationsActive as NotificationsActiveIcon
} from '@mui/icons-material';
import {
    SessionChecker,
    Users,
    Transfers,
    Layout,
    GoCard,
    NotificationTemplates,
    UserDetail,
    Admins,
    AdminProfile,
} from '../../components';

import NotFound from '../../utils/NotFound';

const routesForMenu = [
    {
        path:"users",
        element: <SessionChecker><Users /></SessionChecker>,
        icon:<PersonIcon/>,
        text:"Listado de Clientes",
    },
    {
        path:"transfers",
        element: <SessionChecker><Transfers /></SessionChecker>,
        icon:<CurrencyExchangeIcon/>,
        text:"Solicitudes de Transferencia",
    },
    {
        path:"go-card",
        element: <SessionChecker><GoCard /></SessionChecker>,
        icon:<CreditCardIcon/>,
        text:"Tarjeta GO",
    },
    {
        path:"templates",
        element: <SessionChecker><NotificationTemplates /></SessionChecker>,
        icon:<NotificationsActiveIcon/>,
        text:"Plantillas de Notificaciones"
    },
]

const privateRoutes = [
    {

        path: '/',
        title: 'FXinfinity',
        exact: true,
        element: <SessionChecker><Layout /></SessionChecker>,
        errorElement: <NotFound/>,
        children: [
            {
                path:"users/:userId/detail",
                title: 'FXinfinity',
                element: <SessionChecker><UserDetail/></SessionChecker>,
            },
            {
                path:"admins",
                title: 'FXinfinity',
                element: <SessionChecker><Admins/></SessionChecker>,
            },
            {
                path:"admins/:adminId/profile",
                title: 'FXinfinity',
                element: <SessionChecker><AdminProfile/></SessionChecker>,
            },
            ...routesForMenu
        ],
    }
]



// const privateRoutes = [
//     {
//         element: <SessionChecker />,
//         errorElement: <NotFound/>,
//         children: [
//             {
//                 path: '/',
//                 title: 'FXinfinity',
//                 exact: true,
//                 element: <Layout />,
//                 errorElement: <NotFound/>,
//                 children: [
//                     {
//                         path:"users/detail/:userId",
//                         title: 'FXinfinity',
//                         element: <UserDetail/>,
//                     },
//                     ...routesForMenu
//                 ],
//             }
//         ],
//     },
// ]

export default privateRoutes;

export {routesForMenu}