import theme from 'shared/src/styles/theme'

const Styles = {
    BoxIcon: {
        display: "flex",
        justifyContent:"flex-end",
        margin:2
    },
    column: {
        minWidth: '200px',
        textAlign: 'center',
        fontWeight: 600,
    },
    infoColor: theme.palette.info.main
}

export default Styles;