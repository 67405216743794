import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import logo from "shared/src/assets/images/fdic.png";

import styles from "./styles";

function TopBar() {
  return (
    <AppBar sx={styles.appBar} position="static">
      <Toolbar disableGutters sx={styles.toolBar}>
        <Box>
          <Box alt="app logo" component="img" sx={styles.logo} src={logo} />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default TopBar;
