/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
    Box,
    MenuItem,
    Container,
    Stack,
    Typography,
    Button,
    TextField,
    Switch,
    FormGroup,
    FormControlLabel,
} from '@mui/material'
import { AddTask as AddTaskIcon, Close as CloseIcon } from '@mui/icons-material';
import {
    createNotificationTemplates,
} from 'shared/src/api/notifications';

import {CustomModal, CustomSnackbar, Editor} from '../SharedComponents';
import  { REASON_MAP, TYPE_NOTIFICATIONS_MAP } from 'shared/src/constants';
import {isEditorEmpty} from 'shared/src/utils'

import Styles from "./styles";

const successMessage = "La Plantilla se ha creado correctamente"
const errorMessage = "Ha ocurrido un error, intente más tarde.";

export default function NewTemplate({open, setOpen, requestTemplateListFromService}){
    // TODO: hay estilos hardcodeados
    const [editorValue, setEditorValue] = React.useState("");
    const [destiny, setDestiny] = React.useState('');
    const [type, setType] = React.useState('');
    const [ defaultNoty, setDefaultNoty] = React.useState(false);

    const [templateName, setTemplateName] = React.useState('');
    const [disableButtons, setDisableButtons] = React.useState(true);
    const [opeAlert, setOpenAlert] = React.useState(false);
    const [messageForAlert, setMessageForAlert] = React.useState({});

    // for small notification
    const handleCloseAlert = (e, reason)=> {
        if (reason === 'clickaway') return;
        setOpenAlert(false);
    }

    const handleChangeEditor = value => {
        setEditorValue(value);
    }

    const handleClose = (_, reason) => {
        if(reason === "backdropClick") return;
        setTemplateName('');
        setEditorValue('');
        setDestiny('');
        setType('');
        setDefaultNoty(false);
        setOpen(false);
    };

    const handleCreateNotificationTemplate = async ()=>{

        setDisableButtons(true);
        try {
            await createNotificationTemplates({
                message: editorValue,
                destiny,
                type,
                title: templateName,
                default: defaultNoty? 'si':'no',
            });

            setMessageForAlert({message:successMessage,severity:"success" });
            requestTemplateListFromService();
        } catch (error) {
            console.log(error);
            const { response } = error;
            if(response?.data?.msg){
                setMessageForAlert({message: response.data.msg, severity:"error" });
            } else {
                setMessageForAlert({message:errorMessage,severity:"error" });
            }
            
        }
        setDisableButtons(false);
        setOpenAlert(true);
        handleClose();
    }

    React.useEffect(() => {
        if(destiny !== '' && templateName !== '' && type !== '' && isEditorEmpty(editorValue) === false){
            setDisableButtons(false);
        }else{
            setDisableButtons(true);
        }
    },[destiny, templateName, type, editorValue]);

    return(
        <>
            <CustomModal
                handleClose={handleClose}
                open={open}
                customStyles={{width:'50%'}}
            >
                <Container>
                    <Stack >
                        <Typography variant="h6" gutterBottom sx={{...Styles.title, flex: 1, mb:2, textAlign: 'center'}}>
                            Agregar una nueva Plantilla de Notificación
                        </Typography>
                        <Box  sx={{mb:2, flex: 1}}>
                            <TextField
                                fullWidth
                                label="Título"
                                name="name"
                                value={templateName}
                                onChange={(e)=>setTemplateName(e.target.value)}
                            />
                        </Box>
                    </Stack>
                    <Stack direction={'row'}>
                        <Box  sx={{mb:2, display: 'flex', flex: '0 1 50%', mr:1}}>
                            <TextField
                                fullWidth
                                select
                                label="Motivo"
                                name="reason"
                                value={destiny}
                                onChange={(e)=>setDestiny(e.target.value)}
                            >
                                <MenuItem key="" value="">
                                    --
                                </MenuItem>
                                {
                                    Object.entries(REASON_MAP).map(
                                        ([reason_const,reason_label]) =>
                                        <MenuItem key={reason_const} value={reason_const}>
                                            {reason_label}
                                        </MenuItem>
                                    )
                                }
                            </TextField>
                        </Box>
                        <Box  sx={{mb:2,  display: 'flex', flex: '0 1 50%',ml:1}}>
                            <TextField
                                fullWidth
                                select
                                label="Tipo"
                                name="type"
                                value={type}
                                onChange={(e)=>setType(e.target.value)}
                            >
                                <MenuItem key="" value="">
                                    --
                                </MenuItem>
                                {
                                    Object.entries(TYPE_NOTIFICATIONS_MAP).map(
                                        ([reason_const,reason_label]) =>
                                        <MenuItem key={reason_const} value={reason_const}>
                                            {reason_label}
                                        </MenuItem>
                                    )
                                }
                            </TextField>
                        </Box>
                        <Box  sx={{mb:2,  display: 'flex', flex: '0 1 50%',ml:1}}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={defaultNoty}
                                            onChange={()=>setDefaultNoty(!defaultNoty)}
                                        />
                                    }
                                    label="Convertir en Notificación por defecto"
                                />
                            </FormGroup>
                        </Box>
                    </Stack>
                    <Stack>
                        <Box sx={{flex: 1}}>
                            <Editor editorValue={editorValue} handleChangeEditor={handleChangeEditor} showWildCard/>
                        </Box>
                        <Box  sx={{ ...Styles.BoxActionButtons, mt:2}}>
                            <Button onClick={handleCreateNotificationTemplate} startIcon={<AddTaskIcon />} disabled={disableButtons} variant="contained" color="success" sx={{mr:1}} disableElevation>Agregar Plantilla</Button>
                            <Button onClick={handleClose} startIcon={<CloseIcon />} variant="contained" color="error" sx={{ml:1}} disableElevation >Cancelar</Button>
                        </Box>
                    </Stack>
                </Container>
            </CustomModal>
            {/* small alert at the bottom left to confirm/deny operation*/}
            <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert} />
        </>
    )
}