import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import md5 from 'md5';
import dayjs from 'dayjs';
import { useForm, Controller } from "react-hook-form";
import {
    Grid,
    IconButton,
    Stack,
    Typography,
    TextField,
    MenuItem,
    Button,
    Box,
    CircularProgress,
} from '@mui/material';
import {Edit as EditIcon} from '@mui/icons-material/';

import { getUserAdminFromService, updateAdminUserFromService } from 'shared/src/store/userSlice';
import {DID_TYPES, USER_STATUS, DATE_FORMAT, } from 'shared/src/constants'
import {formatDID} from 'shared/src/utils'

import {CustomModal, CustomSnackbar} from '../../SharedComponents';
import UserDataSkeleton from '../../Users/UserDetail/UserInformation/UserDataSkeleton'

import Styles from './styles';

const requireErrorMessage = "Este campo es requerido";
const successMessage = "la Información del cliente se ha actualizado con éxito"
const errorMessage = "Ha ocurrido un error";

export default function AdminProfile(props) {

    let { adminId } = useParams();
    const { account } = useSelector(state=> state.authSlice);
    const [userValues, setUserValues] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [opeAlert, setOpenAlert] = React.useState(false);
    const [messageForAlert, setMessageForAlert] = React.useState({});
    const handleOpen = () => setOpen(true);
    const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

    const handleClose = (_, reason) => {
        if(reason === "backdropClick") return;
        setOpen(false);
        askForAUser();
    };

    const handleOnSubmit = async (data)=>{
        setActionButtonDisabled(true);
        const result = await updateAdminUserFromService(adminId, data);

        if(result) {
            askForAUser();
            setMessageForAlert({message:successMessage,severity:"success" });
            setOpenAlert(true);
        }else {
            setMessageForAlert({message:errorMessage,severity:"error" });
        }
        setActionButtonDisabled(false);
        handleClose(); // to close modal
    }

    const handleCloseAlert = (e, reason)=> {
        if (reason === 'clickaway') {
            return;
          }
      
        setOpenAlert(false);
    }

    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: {
            username:   '',
            typeDID:   '',
            DID:   '',
            phone:  '',
            email:  '',
            password:   '',
        }
    });

    const askForAUser = async () => {
        const userInfo = await getUserAdminFromService(adminId);
        setUserValues({
            username:   userInfo.username,
            typeDID:   DID_TYPES[userInfo?.typeDID] || '',
            DID:   userInfo.DID  ?? '',
            phone:  userInfo.phone,
            email:  userInfo.email,
            password:   userInfo.password,
            status: USER_STATUS[userInfo?.status],
            createdate:  dayjs(userInfo.createdate).format(DATE_FORMAT),
            avatar: `https://www.gravatar.com/avatar/${md5(userInfo.email.toLowerCase().trim())}?d=identicon`
        });

        reset({
            username: userInfo.username,
            phone: userInfo.phone || '',
            email: userInfo.email,
            password: '',
            DID: userInfo?.DID || '', 
            typeDID: userInfo?.typeDID || '',
            status: userInfo?.status,
        });
    }

    const DID = watch("DID");
    const typeDID = watch("typeDID");

    React.useEffect(() => {
        askForAUser();
    },[]);
    console.log(account?.IDUser, adminId);
    return (
        <Box>
            <Stack direction={'row'} sx={{justifyContent: 'center'}}>
                <Typography variant="h1" sx={{alignSelf: 'center', fontSize: '2rem', mt:2}}>Informacion de Perfil</Typography>
                {(account?.IDUser == adminId) && <IconButton onClick={handleOpen} variant="outlined" sx={Styles.editIconButton} aria-label="delete" size="large">
                    <EditIcon/>
                </IconButton>}
            </Stack>
            <Stack direction={'row'} sx={{margin:2,padding:2, border: '1px solid #ebe9e9'}}>
                <Stack spacing={1} sx={{mb:2, display: 'flex', flex: '0 1 50%', mr:1}}>
                    {userValues?
                        (<>
                            <Typography variant="subtitle1"> <Typography component={'span'} sx={Styles.userInfo}>Nombre:</Typography> {userValues.username}</Typography>
                            <Typography variant="subtitle1"><Typography component={'span'} sx={Styles.userInfo}>Email:</Typography> {userValues.email}</Typography>
                            
                        </>):<UserDataSkeleton/>
                    }
                </Stack>
                <Stack spacing={1} sx={{mb:2, display: 'flex', flex: '0 1 50%', mr:1}}>
                    {userValues?
                        (<>
                            <Typography variant="subtitle1"><Typography component={'span'} sx={Styles.userInfo}>Indentificación:</Typography> {formatDID(userValues)}</Typography>
                            <Typography variant="subtitle1"><Typography component={'span'} sx={Styles.userInfo}>Estado:</Typography> {userValues.status}</Typography>
                        </>):<UserDataSkeleton/>
                    }
                </Stack>
                <Stack spacing={1} sx={{mb:2, display: 'flex', flex: '0 1 50%', mr:1}}>
                    {userValues?
                        (<>
                            <Typography variant="subtitle1"><Typography component={'span'} sx={Styles.userInfo}>Teléfono:</Typography> {userValues.phone}</Typography>
                            <Typography variant="subtitle1"><Typography component={'span'} sx={Styles.userInfo}>Fecha de Registro:</Typography> {userValues.createdate}</Typography>
                        </>):<UserDataSkeleton/>
                    }
                </Stack>
            </Stack>
            <CustomModal handleClose={handleClose} open={open}>
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container alignItems="center" justify="center" direction="row" spacing={3}>
                        <Grid item md={12}>
                            <Typography variant="h6" sx={{mb:2, textAlign: 'center'}}>
                                Editar Administrador.
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                        <Controller
                            name="username"
                            control={control}
                            rules={{required: requireErrorMessage, maxLength: 100}}
                            render={
                                ({ field, fieldState: { invalid, error,  }}) =>(
                                    <TextField
                                        fullWidth
                                        label="Nombre(s) y Apellido(s) *"
                                        error={invalid}
                                        helperText={error?.message}
                                        {...field}
                                        inputRef={field.ref}
                                    />
                                )
                            }
                        />
                        </Grid>
                        <Grid item md={3}>
                            <Controller
                                name="typeDID"
                                control={control}
                                rules={{required: (DID !== '')? requireErrorMessage : false,}}
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                    <TextField
                                        fullWidth
                                        select
                                        label="Tipo"
                                        error={invalid}
                                        helperText={error?.message}
                                        {...field}
                                        inputRef={field.ref}
                                    >
                                        <MenuItem key="" value="">
                                            --
                                        </MenuItem>
                                        {DID_TYPES.map((didType) => <MenuItem key={didType.type} value={didType.type}>{didType.label}</MenuItem>)}
                                    </TextField>)
                                }
                            />
                        </Grid>
                        <Grid item  md={9}>
                            <Controller
                                name="DID"
                                control={control}
                                rules={
                                    {
                                        required: (typeDID !== '')? requireErrorMessage : false, 
                                        pattern: { 
                                            value: /^[0-9-]+$/,
                                            message: "Este campo solo acepta números y guiones (-)"
                                        }
                                    }
                                }
                                render={
                                    ({ field, fieldState: { invalid, error }}) =>(
                                        <TextField
                                        fullWidth
                                        label="Número de Identificación"
                                        error={invalid}
                                        helperText={error?.message}
                                        {...field}
                                        inputRef={field.ref}
                                    />)
                                }
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Controller
                                name="phone"
                                control={control}
                                rules={
                                    {
                                        required: false,
                                        pattern: { 
                                            value: /^[\d+\s]+$/i,
                                            message: "Ingresa solo números y espacios"
                                          }
                                    }
                                }
                                render={
                                    ({ field, fieldState: { invalid, error }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Teléfono"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                name="email"
                                control={control}
                                rules={
                                    {
                                        required: requireErrorMessage,
                                        pattern: { 
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Ingresa una dirección de correo electrónico válida"
                                        }
                                    }
                                }
                                render={
                                    ({ field, fieldState: { invalid, error }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Email *"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                name="password"
                                control={control}
                                rules={
                                    {
                                        required: false,
                                        minLength: {
                                            value: 5,
                                            message: 'Al menos 5 caracteres son requeridos'
                                        }
                                    }
                                }
                                render={
                                    ({ field, fieldState: { invalid, error }}) =>(
                                        <TextField
                                            fullWidth
                                            label="Nueva Contraseña"
                                            error={invalid}
                                            helperText={error?.message}
                                            {...field}
                                            inputRef={field.ref}
                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item md={12} sx={{display: 'flex', justifyContent: 'center'}}>
                            <Button onClick={handleClose} variant="contained" color="error" sx={{mr:1}} disableElevation >Cancelar</Button>
                            <Box sx={{position: 'relative' }}>
                                <Button variant="contained" color="primary" type="submit" disabled={actionButtonDisabled}>
                                    Guardar Cambios
                                </Button>
                                {actionButtonDisabled && (
                                    <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </CustomModal>
            <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert}/>
        </Box>
    );
}