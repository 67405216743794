import { palette } from "shared/src/styles/palette";
import { theme } from "shared";

const styles = {
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  appBar: {
    height: "80px",
    justifyContent: "center",
    backgroundColor: palette.common.white,
  },
  logo: {
    mt: "0px",
    ml: "10px",
    width: "150px",
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
  },
  container: {
    bgcolor: palette.common.white,
    marginTop: 8,
    pt: 2,
  },
  toformAvatar: { m: 1, bgcolor: palette.blue[100] },
};

export default styles;
