import {theme} from 'shared';
import { palette } from 'shared/src/styles/palette';

const Styles = {
    GridManiArea:{
        backgroundColor:'',
        display: {xs: 'none',sm: 'none',md:'block'},
    }
}

export default Styles;