import * as React from 'react';
import {Divider, Box, Paper} from '@mui/material';

import Menu from './Menu';

import Styles from './styles';

function SideBar(){

    return(
        <Paper elevation={0} sx={Styles.Paper}>
            <Box  sx={Styles.Box}>
                <Menu  />
            </Box>
        </Paper>
    );
    
}

export default SideBar;