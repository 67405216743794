/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import dayjs from 'dayjs';
import { useSelector,useDispatch } from 'react-redux';
import {
    Box,
    Grid,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TablePagination,
    CircularProgress,
    Button,
} from '@mui/material'
import {
    AddCircleOutline as AddCircleOutlineIcon,
    Menu as MenuIcon,
} from '@mui/icons-material';

import {DATE_FORMAT, TRANSACTIONS_STATUS} from 'shared/src/constants';

import MenuOptions from './MenuOptions';
import NewTransactionModal from './NewTransactionModal';

import { getTransactionsListFromService } from 'shared/src/store/transactionSlice';

import Styles from "./styles";


export default function Transactions({userId}) {

    const dispatch = useDispatch();
    const { transactionList } = useSelector(state=> state.transactionSlice);

    const [open, setOpen] = React.useState(false);
    const [transactionId, setTransactionId] = React.useState(null);
    const [anchorElForMenuOption, setAnchorElForMenuOption] = React.useState(null);
    const [loadingMoreResults, setLoadingMoreResults] = React.useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const columns = [
        { id: 'orderNumber', label: 'Número de Orden', sx: Styles.column  },
        { id: 'description', label: 'Descripción', sx: Styles.column  },
        { id: 'amount', label: 'Valor', sx: Styles.column  },
        { id: 'status', label: 'Estado', sx: Styles.column  },
        { id: 'date', label: 'Fecha', sx: Styles.column  },
        { id: 'options', label: 'Opciones', sx: Styles.column  },
    ];

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClickMenuIcon = (e, transactionId) => {
        setTransactionId(transactionId);
        setAnchorElForMenuOption(e.currentTarget);
    };

    const handleOpen = () => setOpen(true);

    const formatRow = (row) => {
        return [
            {label:'orderNumber', value: row.orderNro},
            {label:'description', value: row.description},
            {label:'amount', value: `$ ${row.amount} USD`},
            {label:'status', value: TRANSACTIONS_STATUS[row.status] || '--'},
            {label:'date', value: dayjs(row.datetime).format(DATE_FORMAT)},
        ]
    }

    const handleCloseForMenuOption = () => {
        setAnchorElForMenuOption(null);
    };

    const getTransactionslistFromService = () => {
        setLoadingMoreResults(true);
        dispatch(getTransactionsListFromService({ userId, offset: (page * rowsPerPage), limit: rowsPerPage, filter: '' }));
    }

    React.useEffect(()=>{
        getTransactionslistFromService();
    }, [page, rowsPerPage]);

    React.useEffect(()=> {
        if(transactionList.results !== null){
          setLoadingMoreResults(false);
        }
      },[transactionList])


    return(
        <Box>
            <Grid container >
                <Grid item md={12}>
                    <Box sx={Styles.BoxIcon}>
                        <Button onClick={handleOpen} variant="contained" startIcon={<AddCircleOutlineIcon/>} size="small" >
                            Nueva Transacción
                        </Button>
                    </Box>
                </Grid>
                <Grid item md={12}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            sx={column.sx}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!loadingMoreResults && transactionList.results.map((transaction) => (
                                    
                                    <TableRow key={transaction.IDTrans} >
                                        {
                                            formatRow(transaction).map((cellValue) => (
                                                <TableCell
                                                    key={cellValue.label}
                                                    align="center"
                                                >
                                                    <Typography>{cellValue.value}</Typography>
                                                </TableCell>
                                            )
                                            )
                                        }
                                        <TableCell align="center">
                                            <Box>
                                                <IconButton onClick={(e)=>handleClickMenuIcon(e, transaction.IDTrans)}>
                                                    <MenuIcon />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {
                                    loadingMoreResults
                                    && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}><CircularProgress/></Box></TableCell></TableRow>
                                }
                                {
                                    transactionList?.results?.length === 0 && !loadingMoreResults
                                    && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}>Sin registros</Box></TableCell></TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={transactionList?.recordsFiltered || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton
                        showLastButton
                    />
                </Grid>
            </Grid>
            <NewTransactionModal getTransactionslistFromService = {getTransactionslistFromService} open={open} setOpen={setOpen} userId={userId}/>
            {transactionId && <MenuOptions
                userId={userId} 
                getTransactionslistFromService = {getTransactionslistFromService}
                transactionId = {transactionId}
                anchorElForMenuOption = {anchorElForMenuOption}
                handleCloseForMenuOption = {handleCloseForMenuOption}
            />}
        </Box>
    )
}