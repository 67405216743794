import React from "react";
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListItem,
} from '@mui/material'

import { CustomRouterLink } from '../../../../SharedComponents'

function MenuItem(props) {

    const {text, path, icon } = props.item;
    return(
        <ListItem disablePadding>
            <CustomRouterLink to={path}>
                <ListItemButton>
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                </ListItemButton>
            </CustomRouterLink>
        </ListItem>
    );
}

export default MenuItem;