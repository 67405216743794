/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector,useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import {
    Box,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TablePagination,
    CircularProgress,
    IconButton,
} from '@mui/material'

import {
    Menu as MenuIcon,
} from '@mui/icons-material';
import MenuOptions from './MenuOptions';
import { getAccountListFromService } from 'shared/src/store/accountSlice';

import {ACCOUNT_STATE, DATE_FORMAT, BANK_ACCOUT_TYPE} from 'shared/src/constants'

import Styles from "./styles";


export default function BankAccounts({userId}) {

    const dispatch = useDispatch();
    const { accountList } = useSelector(state=> state.accountSlice);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [loadingMoreResults, setLoadingMoreResults] = React.useState(true);
    const [BankAccountId, setBankAccountId] = React.useState(null);
    const [anchorElForMenuOption, setAnchorElForMenuOption] = React.useState(null);

    const columns = [
        { id: 'AccountNumber', label: 'Número de cuenta', sx: Styles.column },
        { id: 'bank', label: 'Banco', sx: Styles.column },
        { id: 'username', label: 'Titular', sx: Styles.column },
        { id: 'type', label: 'Tipo de Cuenta', sx: Styles.column },
        { id: 'status', label: 'Estado', sx: Styles.column },///
        { id: 'createdAt', label: 'Fecha de Registro', sx: Styles.column },
        { id: 'options', label: 'Opciones', sx: Styles.column  },
    ];

    const formatRow = (row) => {
        return [
            { label: 'AccountNumber', value: row.Nro},
            { label: 'bank', value: row.bankname },
            { label: 'username', value: row.username},
            { label: 'type', value: BANK_ACCOUT_TYPE[row.type] || '--' },
            { label: 'status', value: ACCOUNT_STATE[row.state] || '--'},
            { label: 'createdAt', value: dayjs(row.create_at).format(DATE_FORMAT) },
        ]
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const handleCloseForMenuOption = () => {
        setAnchorElForMenuOption(null);
    };

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleClickMenuIcon = (e, BankAccountId) => {
        setBankAccountId(BankAccountId);
        setAnchorElForMenuOption(e.currentTarget);
    };

    const dispactGetAccountListFromService = () => {
        setLoadingMoreResults(true);
        dispatch(getAccountListFromService({userId, offset: (page * rowsPerPage), limit: rowsPerPage, filter: ''}));
    }

    React.useEffect(()=>{
        dispactGetAccountListFromService();
    },[page, rowsPerPage]);

    React.useEffect(()=> {
        if(accountList.results !== null) {
          setLoadingMoreResults(false);
        }
      },[accountList])

    return(<>
        <Box>
            <Grid container >
                <Grid item md={12}>
                    <TableContainer sx={{ maxHeight: 400, mt:7}}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            sx={column.sx}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!loadingMoreResults && accountList.results.map((account) => (
                                    
                                    <TableRow key={account.IDAccount} >
                                        {
                                            formatRow(account).map((cellValue) => (
                                                <TableCell
                                                    key={cellValue.label}
                                                    align="center"
                                                >
                                                    <Typography>{cellValue.value}</Typography>
                                                </TableCell>
                                            )
                                            )
                                        }
                                        <TableCell align="center">
                                            <Box>
                                                <IconButton onClick={(e)=>handleClickMenuIcon(e, account.IDAccount)}>
                                                    <MenuIcon />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {
                                    loadingMoreResults
                                    && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}><CircularProgress/></Box></TableCell></TableRow>
                                    }
                                    {
                                    accountList?.results?.length === 0 && !loadingMoreResults
                                    && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}>Sin registros</Box></TableCell></TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={accountList?.recordsFiltered || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton
                        showLastButton
                    />
                </Grid>
            </Grid>
        </Box>
        <MenuOptions
                userId={userId} 
                getAccountlistFromService = {dispactGetAccountListFromService}
                BankAccountId = {BankAccountId}
                anchorElForMenuOption = {anchorElForMenuOption}
                handleCloseForMenuOption = {handleCloseForMenuOption}
        />
    </>)
}