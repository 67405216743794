import * as React from 'react';
import {Menu, MenuItem, Divider, Stack, Typography, Box, Button } from '@mui/material';
import {Delete as DeleteIcon, Close as CloseIcon} from '@mui/icons-material';

import {deleteUser} from 'shared/src/api/users';

import { CustomRouterLink, CustomModal, CustomSnackbar } from '../../SharedComponents';


const successMessage = "El usuario se ha eliminado correctamente.";
const errorMessage = "Ha ocurrido un error, intente más tarde.";

export default function Actions(props) {
    const {
      handleClose,
      anchorEl,
      open,
      selectedUser,
      fetchUser,
    } = props;

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false);
    const [actionButtonsDisabled, setActionButtonsDisabled] = React.useState(false);
    const [messageForAlert, setMessageForAlert] = React.useState({});
    const [opeAlert, setOpenAlert] = React.useState(false);

    const handleCloseDeleteConfirmation = (_, reason) => {
      if(reason === "backdropClick") return;
      setOpenDeleteConfirmation(false);
      handleClose();
    }

    const handOpenDeleteConfirmation = () => {
      setOpenDeleteConfirmation(true);
    }

    // funtions to control delete confirmation
    const handleDeleteUser = async _ => {
      setActionButtonsDisabled(true);
      try {
        await deleteUser(selectedUser);
          fetchUser()
          setMessageForAlert({message:successMessage,severity:"success" });
      } catch (error) {
        setMessageForAlert({message:errorMessage,severity:"error" });
      }
      
      setActionButtonsDisabled(false);
      // setOpenAlert(true); Disable for now
      setOpenDeleteConfirmation(false);
      handleClose();
    }

    // for small notification
    const handleCloseAlert = (_, reason)=> {
      if (reason === 'clickaway') return;
      setOpenAlert(false);
    }
  return (<>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem><CustomRouterLink to={`${selectedUser}/detail`}>Ver Usuario</CustomRouterLink></MenuItem>
        <Divider />
        <MenuItem onClick={handOpenDeleteConfirmation}>Eliminar Usuario</MenuItem>
      </Menu>
      {/* to confirm delete notifications*/}
      <CustomModal
        handleClose={handleCloseDeleteConfirmation}
        open={openDeleteConfirmation}
        customStyles={{width:'500px'}}
      >
        <Stack>
            <Typography variant="h7" align="center">¿Seguro que desea eliminar este Usuario?</Typography>
            <Box sx={{display:'flex', justifyContent: 'center', mt:2}}>
              <Button
                  onClick={handleCloseDeleteConfirmation}
                  disabled={actionButtonsDisabled}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="success"
                  sx={{mr:1}}
                  disableElevation
              >
                  Cancelar
              </Button>
              <Button
                  onClick={(e)=>handleDeleteUser(e)}
                  disabled={actionButtonsDisabled}
                  startIcon={<DeleteIcon />}
                  variant="contained"
                  color="error"
                  sx={{ml:1}}
                  disableElevation
              >
                  Eliminar
              </Button>
            </Box>
        </Stack>
      </CustomModal>
      {/* small alert at the bottom left to confirm/deny operation*/}
      <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert} />
  </>);
}