import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import {InputBase, IconButton} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const Search = styled('div')(({ theme }) => ({
    display:'flex',
    justifyContent: 'flex-end',
    margin: '10px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    border: '1px solid #acaaaa',
    marginRight: '10px',
    borderRadius: '5px',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '80%',
      [theme.breakpoints.up('md')]: {
        width: '25ch',
      },
    },
  }));

export default function SearchBar({handleOnChangeSearch}) {

  const [searchValue, setSearchValue] = React.useState('');

  const handlechange = (e) => {
    setSearchValue(e.target.value);
    handleOnChangeSearch(e.target.value);
  }

  const handleClearField = () => handlechange({target:{value:''}})
  
  return (
      <Search>
          <SearchIconWrapper>
              {searchValue === "" ?
                <SearchIcon />:
                <IconButton onClick={handleClearField} sx={{zIndex:100000}}>
                  <ClearIcon />
                </IconButton>
              }
          </SearchIconWrapper>
          <StyledInputBase
            value={searchValue}
            placeholder="Buscar…"
            inputProps={{ 'aria-label': 'search' }}
            onChange={handlechange}
          />
      </Search>
  );
}