import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { CustomRouterLink } from '../SharedComponents';

export default function Actions(props) {
    const {
      handleClose,
      anchorEl,
      open,
      selectedAdmin
    } = props;
  return (
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem><CustomRouterLink to={`/admins/${selectedAdmin}/profile`}>Ver Perfil</CustomRouterLink></MenuItem>
        <MenuItem onClick={handleClose}></MenuItem>
      </Menu>
  );
}