/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import dayjs from 'dayjs';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  TablePagination,
  Stack,
} from '@mui/material';

import { Menu as MenuIcon } from '@mui/icons-material';

import Actions from './Actions';

import { listGoCards } from 'shared/src/api/goCards';
import { DATE_FORMAT } from 'shared/src/constants';
import {formatDID} from 'shared/src/utils';

import Styles from './styles';

const columns = [
  { id: 'fisrtName', align: 'center', label: 'Nombre', minWidth: 170 },
  { id: 'country', align: 'center', label: 'País', minWidth: 170 },
  { id: 'state', align: 'center', label: 'Estado/Depto', minWidth: 170 },
  { id: 'city', align: 'center', label: 'Ciudad', minWidth: 170 },
  { id: 'DID', align: 'center', label: 'Identificación', minWidth: 100 },
  { id: 'status', align: 'center', label: 'Etapa', minWidth: 100, maxWidth:500 },
  { id: 'createdat', align: 'center', label: 'Fecha de Registro', minWidth: 100, maxWidth:500 },
  { id: 'options', align: 'center', label: 'Opciones', minWidth: 170 },
];

const defaultFormatForGoCardResponse = {
  results: null,
  limit:0,
  skip:0,
  recordsTotal:0,
} 

export default function GoCard() {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedGoCardId, setSelectedGoCardId] = React.useState(null);
  const [selectedGoCardUserId, setSelectedGoCardUserId] = React.useState(null);
  const [selectedGoCarStatusAt, setSelectedGoCarStatusAt] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loadingMoreResults, setLoadingMoreResults] = React.useState(true);
  const [goCardList, setGoCardList] = React.useState(defaultFormatForGoCardResponse);

  const formatRow = (row) => {
    return [
      {label: 'username', value: row.user?.username || '--'},
      {label: 'country' , value: row.country},
      {label: 'state' , value: row.state},
      {label: 'city' , value: row.city},
      {label: 'DID', value: formatDID(row)},
      {label: 'status', value: (row.status_at === null)? 'Por Procesar' : 'Procesada' },
      {label: 'createdat', value: dayjs(row.create_at).format(DATE_FORMAT)},
    ]
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleMenuClick = (event, id, userId, status_at) => {
    setSelectedGoCardId(id);
    setSelectedGoCardUserId(userId);
    setSelectedGoCarStatusAt(status_at);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const requestGoCardListFromService = async () => {
    handleClose(); // evitar que el menu de acciones se quede abierto 
    setLoadingMoreResults(true);
    try {
      const { status, data: { data } } = await listGoCards({offset: (page * rowsPerPage), limit: rowsPerPage, filter: ''});

      if(status === 200){
      
        setGoCardList(data);
      } else {
        setGoCardList({...defaultFormatForGoCardResponse, results: []});
      }
    } catch (error) {
      console.log(error);
      setGoCardList({...defaultFormatForGoCardResponse, results: []});
    }
  }

  React.useEffect(() => {
    requestGoCardListFromService()
  }, [page, rowsPerPage]);

  React.useEffect(()=> {
    if(goCardList.results !== null) {
      setLoadingMoreResults(false);
    }
  },[goCardList])

  return (
    <>
    <Stack>
      <Typography variant="h1" sx={{alignSelf: 'center', fontSize: '2rem', mt:2, mb:4}}>Solicitudes de Tarjetas Go</Typography>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, maxWidth: column.maxWidth, fontWeight: 600 }}
                        >
                          {column.label}
                        </TableCell>
                    ))}
                  </TableRow>
              </TableHead>
              <TableBody>
                  {!loadingMoreResults  && goCardList.results
                  .map((row) => {
                      return (
                        <TableRow hover role="checkbox" key={row.IDCard}>
                          {
                            formatRow(row).map((cellValue) => (
                                <TableCell
                                  key={cellValue.label}
                                  align="center"
                                >
                                  {cellValue.label === "message"?
                                  <Typography dangerouslySetInnerHTML={{__html: cellValue.value}}></Typography>:
                                  <Typography>{cellValue.value}</Typography>}
                                </TableCell>
                              )
                            )
                          }
                          <TableCell
                            key="actions"
                            align="center"
                            
                          >
                            <IconButton onClick={(e)=>handleMenuClick(e, row.IDCard, row.IDUser, row.status_at)}>
                              <MenuIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                  })}
                  {
                  loadingMoreResults
                    && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}><CircularProgress/></Box></TableCell></TableRow>
                  }
                  {
                  goCardList?.results?.length === 0 && !loadingMoreResults
                    && <TableRow><TableCell colSpan={7}><Box sx={{display: 'flex', justifyContent: 'center',}}>Sin registros</Box></TableCell></TableRow>
                  }
              </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[2,10, 25, 100]}
          component="div"
          count={goCardList?.recordsFiltered || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Paper>
    </Stack>
      {anchorEl && 
        <Actions 
          requestGoCardListFromService={requestGoCardListFromService}
          handleClose={handleClose}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          selectedGoCardId={selectedGoCardId}
          selectedGoCardUserId = {selectedGoCardUserId}
          selectedGoCarStatusAt={selectedGoCarStatusAt}
        />
      }
    </>
  );
}