/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Grid,
    MenuItem,
    Container,
    Stack,
    Typography,
    ListItem,
    ListItemButton,
    List,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Button,
    TextField,
} from '@mui/material'
import { AddTask as AddTaskIcon, Close as CloseIcon, Notifications as NotificationsIcon } from '@mui/icons-material';
import {getNotificationTemplatesListFromService, createNotificationFromService } from 'shared/src/store/notificationSlice';
import { getUserFromService } from 'shared/src/store/userSlice';
import Editor from '../../../../SharedComponents/Editor';

import {CustomModal, CustomRouterLink, CustomSnackbar} from '../../../../SharedComponents';
import  { REASON_MAP, TYPE_NOTIFICATIONS_MAP } from 'shared/src/constants';
import {existWildcard, isEditorEmpty, wildCardMaper,getRegexForWildCards} from 'shared/src/utils'

import Styles from "./styles";

const successMessage = "La notificación se ha creado correctamente"
const errorMessage = "Ha ocurrido un error, intente más tarde.";

export default function NewNotificationModal({open, closeNewNotificationModal, userId}){
    // TODO: hay estilos hardcodeados
    const dispatch = useDispatch();
    const { notificationTemplateList } = useSelector((state)=>state.notificationSlice);
    const [editorValue, setEditorValue] = React.useState("");
    const [notificationTemplates, setNotificationTemplates] = React.useState([]);
    const [reason, setReason] = React.useState('');
    const [type, setType] = React.useState('');
    const [disableButtons, setDisableButtons] = React.useState(true);
    const [opeAlert, setOpenAlert] = React.useState(false);
    const [messageForAlert, setMessageForAlert] = React.useState({});
    const [erroWildcard, setErrorWildcard] = React.useState('none');

    // for small notification
    const handleCloseAlert = (e, reason)=> {
        if (reason === 'clickaway') return;
        setOpenAlert(false);
    }

    const handleChangeEditor = value => {
        setErrorWildcard('none');
        setEditorValue(value);
    }

    const handleClose = (_, reason) => {
        if(reason === "backdropClick") return;
        setEditorValue('');
        setReason('');
        setType('');
        setErrorWildcard('none');
        closeNewNotificationModal();
    };

    const tempParseTemplate = (toConvert, wildCardMap) => {

        return toConvert.map((template)=>{
            return({
                id: template.IDTemplate,
                title: template.title,
                body: template.message.replace(getRegexForWildCards(), function(match) {
                    return wildCardMap[match] || match;
                  }),
                reason: template.destiny,
                type: template.type,
                visibleReason: REASON_MAP[template.destiny] || "No definida",
            });
        });
    }

    const loadTemplates = async () => {

        if(!notificationTemplateList) return;
        const userInfo = await getUserFromService(userId);
        //variables mapeadas y listas
        const wildCardMap = wildCardMaper(userInfo);
        setNotificationTemplates(tempParseTemplate(notificationTemplateList?.results || [],wildCardMap));
    }

    const handleSelectTemplate = (_,templateId) => {
        const selected = notificationTemplates.find(template => template.id === templateId);
        setReason(selected.reason);
        setType(selected.type);
        setEditorValue(selected.body);
    }

    const handleAssignNotification = async ()=>{
        //verificamos que los datos sean válidos
        if(existWildcard(editorValue)){
            setErrorWildcard('block');
            return;
        }
        setDisableButtons(true);
        const data = await createNotificationFromService(
            userId,
            {
                body: editorValue,
                destiny:reason,
                type: type,
                title: '', // TBD
                // status: '', // TBD
            }
        );
        setDisableButtons(false);
        if(data){
            setMessageForAlert({message:successMessage,severity:"success" });
            //dispatch(getNotificationListFromService(userId));
        }else{
            setMessageForAlert({message:errorMessage,severity:"error" });
        }
        setOpenAlert(true);
        handleClose();
    }

    React.useEffect(() => {
        if(reason !== '' && type !== '' && isEditorEmpty(editorValue) === false){
            setDisableButtons(false);
        }else{
            setDisableButtons(true);
        }
    },[reason, type, editorValue]);
    
    React.useEffect(() => {
        loadTemplates();
    },[notificationTemplateList]);

    React.useEffect(()=>{
        dispatch(getNotificationTemplatesListFromService({ offset:0, limit:100, filter:""}));
    },[]);

    return(
        <>
            <CustomModal
                handleClose={handleClose}
                open={open}
                customStyles={{width:'70%'}}
            >
                <Container>
                    <Stack>
                        <Typography variant="h6" gutterBottom sx={Styles.title}>
                            Agregar una nueva notificación al cliente
                        </Typography>
                    </Stack>
                    <Stack>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <Box>
                                    <Typography sx={{textAlign:'center', mb:1}}>1&#41; Selecionar una Plantilla</Typography>
                                </Box>
                                <Box sx={Styles.Box}>
                                    <List dense sx={Styles.List}>
                                        {notificationTemplates.length !== 0 && notificationTemplates.map((value) => {
                                            return (
                                            <ListItem
                                                key={value.id}
                                                onClick={(e)=>{handleSelectTemplate(e,value.id)}}
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <NotificationsIcon/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText  primary={value.title}  secondary={`${value.visibleReason} > ${value.type}`} />
                                                </ListItemButton>
                                            </ListItem>
                                            );
                                        })}
                                        {notificationTemplates.length === 0 && 
                                            <Box sx={{width:'100%', height:'100%', display: 'flex', alignItems: 'center', padding:2}}>
                                                <Typography align='justify'>
                                                    No ha creado ninguna Plantilla de Notificaciones, para crear una diríjase a <CustomRouterLink to={'/notifications'} sx={{textDecoration: 'underline', color: '#2f6ed5'}}>Plantillas de notificaciones</CustomRouterLink>
                                                </Typography>
                                            </Box>
                                        }
                                    </List>
                                </Box>
                            </Grid>
                            <Grid item md={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                <Box>
                                    <Typography  sx={{textAlign:'center', mb:1}}>2&#41; Redactar cuerpo de la notificación</Typography>
                                </Box>
                                <Stack direction='row' spacing={1} >
                                    <Box  sx={{mb:2, flex: 1}}>
                                        <TextField
                                            fullWidth
                                            select
                                            label="Motivo"
                                            name="reason"
                                            value={reason}
                                            onChange={(e)=> setReason(e.target.value)}
                                        >
                                            <MenuItem key="" value="">
                                                --
                                            </MenuItem>
                                            {
                                                Object.entries(REASON_MAP).map(
                                                    ([reason_const,reason_label]) =>
                                                    <MenuItem key={reason_const} value={reason_const}>
                                                        {reason_label}
                                                    </MenuItem>
                                                )
                                            }
                                        </TextField>
                                    </Box>
                                    <Box  sx={{mb:2, flex: 1}}>
                                        <TextField
                                            fullWidth
                                            select
                                            label="Tipo"
                                            name="type"
                                            value={type}
                                            onChange={(e)=> setType(e.target.value)}
                                        >
                                            <MenuItem key="" value="">
                                                --
                                            </MenuItem>
                                            {
                                                Object.entries(TYPE_NOTIFICATIONS_MAP).map(
                                                    ([reason_const,reason_label]) =>
                                                    <MenuItem key={reason_const} value={reason_const}>
                                                        {reason_label}
                                                    </MenuItem>
                                                )
                                            }
                                        </TextField>
                                    </Box>
                                </Stack>
                                
                                <Box sx={{flexGrow: 1}}>
                                    <Editor editorValue={editorValue} handleChangeEditor={handleChangeEditor}/>
                                </Box>
                                <Box><Typography sx={{display: erroWildcard, fontSize:'13px'}} color={'red'}>* Debe completar todas las Variables de la plantilla antes de enviar ([ejemplo])</Typography></Box>
                            </Grid>
                            <Grid item md={12}>
                                <Box  sx={{ ...Styles.BoxActionButtons}}>
                                    <Button onClick={handleAssignNotification} startIcon={<AddTaskIcon />} disabled={disableButtons} variant="contained" color="success" sx={{mr:1}} disableElevation>Asignar</Button>
                                    <Button onClick={handleClose} startIcon={<CloseIcon />} variant="contained" color="error" sx={{ml:1}} disableElevation >Cancelar</Button>
                                </Box>
                            </Grid>
                        </Grid>

                    </Stack>
                </Container>
            </CustomModal>
            {/* small alert at the bottom left to confirm/deny operation*/}
            <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert} />
        </>
    )
}