import * as React from 'react';
import { deleteGoCard} from 'shared/src/api/goCards'
import {Menu, MenuItem, Stack, Typography, Box, Button, Divider} from '@mui/material';
import {Delete as DeleteIcon, Close as CloseIcon} from '@mui/icons-material';
import EditGoCardRequest from './EditGoCardRequest';
import {CustomModal, CustomSnackbar, CustomRouterLink } from '../SharedComponents'

const successMessage = "La Solictud de tarjeta Go se ha eliminado correctamen"
const errorMessage = "Ha ocurrido un error, intente más tarde.";

export default function Actions(props) {
  const {
    handleClose,
    anchorEl,
    open,
    selectedGoCardId,
    selectedGoCardUserId,
    requestGoCardListFromService,
    selectedGoCarStatusAt,
  } = props;

  const [actionButtonsDisabled, setActionButtonsDisabled] = React.useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false);
  const [opeAlert, setOpenAlert] = React.useState(false);
  const [messageForAlert, setMessageForAlert] = React.useState({});
  const [openEditGoCardRequest, setOpenEditGoCardRequest] = React.useState(false);


  const handleCloseDeleteConfirmation = (e, reason) => {
    if(reason === "backdropClick") return;
    setOpenDeleteConfirmation(false);
  }

  const handOpenDeleteConfirmation = () => {
    setOpenDeleteConfirmation(true);
  }

  const handleDeleteNotification = async (_,selectedGoCardId) => {
    setActionButtonsDisabled(true);
    try {
          await deleteGoCard(selectedGoCardId);
          setMessageForAlert({message:successMessage,severity:"success" });
    } catch (error) {
       setMessageForAlert({message:errorMessage,severity:"error" });
    }

    setActionButtonsDisabled(false);
    setOpenAlert(true);
    setOpenDeleteConfirmation(false);
    requestGoCardListFromService()
  }

  // for small notification
  const handleCloseAlert = (_, reason)=> {
    if (reason === 'clickaway') return;
    setOpenAlert(false);
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disabled={(selectedGoCarStatusAt === null) ? false : true}
          onClick={()=>setOpenEditGoCardRequest(true)}
        >
          Cambiar Estado
        </MenuItem>
        <MenuItem><CustomRouterLink to={`/users/${selectedGoCardUserId}/detail`}>Ver Usuario</CustomRouterLink></MenuItem>
        <Divider />
        <MenuItem onClick={handOpenDeleteConfirmation}>Borrar</MenuItem>
      </Menu>
      {/* to confirm delete notifications*/}
      <CustomModal
        handleClose={handleCloseDeleteConfirmation}
        open={openDeleteConfirmation}
        customStyles={{width:'500px'}}
      >
        <Stack>
            <Typography variant="h7" align="center">¿Seguro que desea Borrar la solicitud de tarjeta Go?</Typography>
            <Box sx={{display:'flex', justifyContent: 'center', mt:2}}>
                <Button
                    onClick={(e)=>handleDeleteNotification(e, selectedGoCardId)}
                    disabled={actionButtonsDisabled}
                    startIcon={<DeleteIcon />}
                    variant="contained"
                    color="error"
                    sx={{mr:1}}
                    disableElevation
                >
                    Borrar
                </Button>
                <Button
                    onClick={handleCloseDeleteConfirmation}
                    disabled={actionButtonsDisabled}
                    startIcon={<CloseIcon />}
                    variant="contained"
                    color="success"
                    sx={{ml:1}}
                    disableElevation
                >
                    Cancelar
                </Button>
            </Box>
        </Stack>
      </CustomModal>
      {/* small alert at the bottom left to confirm/deny operation*/}
      <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert} />
      {/* Para editar las notificaciones*/}
        {openEditGoCardRequest && <EditGoCardRequest
          requestGoCardListFromService = {requestGoCardListFromService}
          open = {openEditGoCardRequest}
          setOpen = {setOpenEditGoCardRequest}
          selectedGoCardId = {selectedGoCardId}
        />}
    </>
  );
}