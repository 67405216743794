import React from 'react';
import md5 from 'md5';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {
    Box,
    Tooltip,
    IconButton,
    Avatar,
    Menu,
    MenuItem,
    Typography,
    Divider,
} from '@mui/material';
import {logout} from 'shared/src/store/authSlice';

export default function AccountActions() {
  const { account } = useSelector(state=> state.authSlice);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [avatarImage, setAvatarImage] = React.useState('');

    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

    const handleNavegateListAdmins = () => {
      setAnchorElUser(null);
      navigate('admins');
    }

    const handleNavegateProfile = () => {
      setAnchorElUser(null);
      navigate(`admins/${account?.IDUser}/profile`);
    }

    const handleLogout = () => {
        dispatch(logout());
        setAnchorElUser(null);
        navigate(0);
    }


    React.useEffect(()=>{
      if(account === null){
        navigate(0);
      } else {
        setAvatarImage(`https://www.gravatar.com/avatar/${md5(account.email.toLowerCase().trim())}?d=identicon`);
      };
    },[account, navigate]);


    return(
        <Box>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src={avatarImage} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              
                <MenuItem onClick={handleNavegateProfile}> 
                  <Typography textAlign="left">Ver mi Perfil</Typography>
                </MenuItem>
                <MenuItem onClick={handleNavegateListAdmins}>
                  <Typography textAlign="left">Ver Listado de admin</Typography>
                </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="left">Cerrar Sesión</Typography>
              </MenuItem>
            </Menu>
        </Box>
    );
}