import React from "react";
import { useForm, Controller } from "react-hook-form";
import {Grid, Typography, Button, TextField, Box, CircularProgress, MenuItem} from '@mui/material';
import {CustomModal, CustomSnackbar} from '../../../SharedComponents';
import {updateTransaction, getTransaction} from 'shared/src/api/transactions';
import { TRANSACTIONS_STATUS } from 'shared/src/constants';

const requireErrorMessage = "Este campo es requerido";
const successMessage = "La transacción se ha actualizado con exito"
const errorMessage = "Ha ocurrido un error";

const defaultValuesFordateField = {
    status: '',
}


export default function UpdateTransactionModal({ getTransactionslistFromService, transactionId,  open, setOpen, userId}) {

    const [messageForAlert, setMessageForAlert] = React.useState({});
    const [opeAlert, setOpenAlert] = React.useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);

    const handleClose = (_, reason) => {
        if(reason === "backdropClick") return;
        setActionButtonDisabled(false);
        reset(defaultValuesFordateField);
        setOpen(false);
    };

    const { control, handleSubmit, reset } = useForm({
        defaultValues: defaultValuesFordateField,
    });

    const handleOnSubmit = async data =>{
        console.log(data);
        setActionButtonDisabled(true);

        try {

            const { status } = await updateTransaction(userId, transactionId, data);

            if( status === 200 ) {
                setMessageForAlert({message:successMessage,severity:"success" });
            }else {
                setMessageForAlert({message:errorMessage,severity:"error" });
            }
            
        } catch (error) {
            setMessageForAlert({message:errorMessage,severity:"error" });
        }
        
        setOpenAlert(true);
        getTransactionslistFromService();
        reset(defaultValuesFordateField);
        handleClose(); // to close modal
    }

    // for small notification
    const handleCloseAlert = (e, reason)=> {
        if (reason === 'clickaway') return;
        setOpenAlert(false);
    }

    const getTransactionInformation =  async () => {
        setActionButtonDisabled(true);
        const { data: {data} } = await getTransaction(userId, transactionId);
        reset({...defaultValuesFordateField, status: data.status});
        setActionButtonDisabled(false);
    }

    React.useEffect(() => {
        getTransactionInformation();
    },[]);

    return(
        <>
            <CustomModal handleClose={handleClose} open={open} customStyles={{width:'400px'}}>
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container alignItems="center" justify="center" direction="row" spacing={3}>
                        <Grid item md={12}>
                            <Typography variant="h6" sx={{mb:2, textAlign: 'center'}}>
                                Actualizar status de Transacción
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Controller
                                name="status"
                                control={control}
                                rules={
                                    {
                                        required: requireErrorMessage,
                                    }
                                }
                                render={
                                    ({ field, fieldState: { invalid, error,  }}) =>(
                                    <TextField
                                        disabled={actionButtonDisabled}
                                        fullWidth
                                        select
                                        label="Estado *"
                                        error={invalid}
                                        helperText={error?.message}
                                        {...field}
                                        inputRef={field.ref}
                                    >
                                        <MenuItem key="" value="">
                                            --
                                        </MenuItem>
                                        {Object.entries(TRANSACTIONS_STATUS).map(([key,value]) => <MenuItem key={key} value={key}>{value}</MenuItem>)}
                                    </TextField>)
                                }
                            />
                        </Grid>
                        <Grid item md={12} sx={{display: 'flex', justifyContent: 'center'}}>
                            <Button onClick={handleClose} variant="contained" color="error" sx={{mr:1}} disableElevation >Cancelar</Button>
                            <Box sx={{position: 'relative' }}>
                                <Button variant="contained" color="primary" type="submit" disabled={actionButtonDisabled}>
                                    Aceptar
                                </Button>
                                {actionButtonDisabled && (
                                    <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </CustomModal>
            <CustomSnackbar opeSnackbar={ opeAlert } messageForAlert={messageForAlert} handleCloseAlert={handleCloseAlert}/>
        </>
    );
}